import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { FormDialog, selectOptionSchema } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps,
  SelectOption
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Loader } from "components/common/Loader";
import { Menu } from "components/common/Menu";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as clustersActions from "modules/clusters/actions";
import {
  clusterSelector,
  clusterTemplatesSelector,
  isClusterDeletingSelector,
  isClusterResizingSelector
} from "modules/clusters/selectors";
import * as enterprisesActions from "modules/enterprises/actions";
import { organizationSelector } from "modules/enterprises/selectors";
import * as instancesActions from "modules/instances/actions";
import { flavorsSelector } from "modules/instances/selectors";
import { FLAVOR_TYPE } from "modules/instances/types";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import * as pollingActions from "modules/polling/actions";
import * as projectsActions from "modules/projects/actions";
import { projectSelector } from "modules/projects/selectors";
import {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { downloadFile } from "utils/downloadFile";
import { filterVersions } from "utils/filterVersions";
import { generateSearchString } from "utils/generateSearchString";
import { getParentPath } from "utils/getParentPath";
import { number, string } from "yup";
import { API_PATH } from "../../axios";
import { MIN_NODE_COUNT, ROUTES } from "../../constants";
import * as s from "./styles";
import { DIALOG_TYPES, TABS } from "./types";

const POLL_ID_PREFIX = "CLUSTER";

const POLL_IDS = {
  cluster: "CLUSTER",
  clusterTemplates: "CLUSTER_TEMPLATES",
  flavors: "FLAVORS"
};

const TAB_TITLES = {
  [TABS.INFO]: "Info",
  [TABS.NODES]: "Nodes",
  [TABS.MISCELLANEOUS]: "Miscellaneous",
  [TABS.LABELS]: "Labels"
};

export const Cluster: FC = () => {
  const dispatch = useDispatch();
  const matchParams = useParams<{
    organizationId: string;
    regionId: string;
    projectId: string;
    clusterId: string;
  }>();
  const history = useNavigate();
  const organization = useSelector(organizationSelector);
  const project = useSelector(projectSelector);
  const cluster = useSelector(clusterSelector);
  const clusterTemplates = useSelector(clusterTemplatesSelector);
  const isClusterResizing = useSelector(isClusterResizingSelector);
  const isClusterDeleting = useSelector(isClusterDeletingSelector);
  const isOperationInProgress = isClusterResizing || isClusterDeleting;
  const previousIsOperationInProgress = usePrevious(isOperationInProgress);
  const flavors = useSelector(flavorsSelector);
  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.EDIT, isOpened: false });
  const activeTabIndexFromParam = Object.keys(TAB_TITLES).find(
    (key) =>
      TAB_TITLES[key] &&
      String(TAB_TITLES[key]).toLowerCase() ===
        new URLSearchParams(location.search).get("tab")
  );
  const [activeTabIndex, setActiveTabIndex] = useState(
    Number(activeTabIndexFromParam || TABS.INFO) as TABS
  );
  const [isActionsMenuOpened, setIsActionsMenuOpened] =
    useState<boolean>(false);
  const actionsMenuButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleChangeTab = useCallback((e, value: number) => {
    setActiveTabIndex(value);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
  }, [dialog]);

  const handleActionsMenuButtonClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      actionsMenuButtonRef.current = e.currentTarget;
      setIsActionsMenuOpened(!isActionsMenuOpened);
    },
    [isActionsMenuOpened]
  );

  const handleActionsMenuClose = useCallback(() => {
    setIsActionsMenuOpened(false);
  }, []);

  const handleActionsMenuItemClick = useCallback(
    (callback: () => void) => () => {
      setIsActionsMenuOpened(false);
      callback();
    },
    []
  );

  const handleUpgradeClusterMenuItemClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.UPGRADE,
      isOpened: true
    });
  }, []);

  const handleDownloadKubeconfigFileClick = useCallback(() => {
    downloadFile(
      `${API_PATH}gotham-${matchParams.regionId}-kubernetes/method/${matchParams.projectId}/clusters/${matchParams.clusterId}/kubeconfig`
    );
    dispatch(
      notificationsActions.showNotification({
        title: "Kubeconfig file is generating. Download will start soon.",
        type: NOTIFICATION_TYPES.INFO
      })
    );
  }, [
    matchParams.regionId,
    matchParams.projectId,
    matchParams.clusterId,
    dispatch
  ]);

  const actions = [
    {
      label: "Download kubeconfig file",
      handler: handleDownloadKubeconfigFileClick
    },
    {
      label: "Upgrade",
      handler: handleUpgradeClusterMenuItemClick
    }
  ];

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Projects",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: project?.name || "",
      url: generatePath(ROUTES.PROJECT, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: "Clusters",
      url: generatePath(ROUTES.CLUSTERS, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: cluster?.name || "",
      url: generatePath(ROUTES.CLUSTER, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        clusterId: matchParams.clusterId
      })
    }
  ];

  const handleEditClusterButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.EDIT,
      isOpened: true
    });
  }, []);

  const handleDeleteClusterButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.DELETE,
      isOpened: true
    });
  }, []);

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.cluster}`,
        action: clustersActions.getCluster.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: matchParams.clusterId!
        })
      })
    );
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
    dispatch(
      projectsActions.getProject.started({
        regionId: matchParams.regionId!,
        id: matchParams.projectId!
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.clusterTemplates}`,
        action: clustersActions.getClusterTemplates.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.flavors}`,
        action: instancesActions.getFlavors.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          type: FLAVOR_TYPE.CLUSTER
        })
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(enterprisesActions.clear());
    dispatch(projectsActions.clear());
    dispatch(clustersActions.clear());
    dispatch(instancesActions.clear());
  });

  useEffect(() => {
    if (previousIsOperationInProgress && !isOperationInProgress) {
      if (!cluster) {
        history(getParentPath(location.pathname));
      } else {
        dispatch(
          clustersActions.getCluster.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            id: matchParams.clusterId!
          })
        );
      }
    }
  }, [
    cluster,
    history,
    previousIsOperationInProgress,
    isOperationInProgress,
    dispatch,
    matchParams.projectId,
    matchParams.clusterId,
    matchParams.regionId
  ]);

  const handleConfirmEditCluster = useCallback(
    (data: { value: string }) => {
      dispatch(
        clustersActions.resizeCluster.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: matchParams.clusterId!,
          data: {
            node_count: Number(data.value)
          }
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      matchParams.clusterId,
      matchParams.projectId,
      matchParams.regionId,
      handleCloseDialog
    ]
  );

  const handleConfirmUpgradeCluster = useCallback(
    (data: { cluster_template: SelectOption }) => {
      dispatch(
        clustersActions.upgradeCluster.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: matchParams.clusterId!,
          data: {
            cluster_template: data.cluster_template.value
          }
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      matchParams.regionId,
      matchParams.projectId,
      matchParams.clusterId,
      handleCloseDialog
    ]
  );

  const handleConfirmDeleteCluster = useCallback(() => {
    dispatch(
      clustersActions.deleteCluster.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!,
        id: matchParams.clusterId!
      })
    );
    handleCloseDialog();
  }, [
    dispatch,
    matchParams.projectId,
    matchParams.clusterId,
    matchParams.regionId,
    handleCloseDialog
  ]);

  useEffect(() => {
    switch (activeTabIndex) {
      case TABS.INFO:
        history({
          search: generateSearchString({
            tab: TAB_TITLES[TABS.INFO]
          })
        });
        break;
      case TABS.NODES:
        history({
          search: generateSearchString({
            tab: TAB_TITLES[TABS.NODES]
          })
        });
        break;
      case TABS.MISCELLANEOUS:
        history({
          search: generateSearchString({
            tab: TAB_TITLES[TABS.MISCELLANEOUS]
          })
        });
        break;
      case TABS.LABELS:
        history({
          search: generateSearchString({
            tab: TAB_TITLES[TABS.LABELS]
          })
        });
        break;
    }
  }, [history, activeTabIndex]);

  const masterFlavor = flavors
    ? flavors.find(
        (flavor) => cluster && flavor.id === cluster.master_flavor_id
      )?.name || ""
    : "";

  const nodeFlavor = flavors
    ? flavors.find((flavor) => cluster && flavor.id === cluster.flavor_id)
        ?.name || ""
    : "";

  const tabContent = [
    <>
      <Typography>Status: {cluster?.status}</Typography>
      <Typography>Errors:</Typography>
      {cluster?.faults &&
        Object.keys(cluster.faults).map((key) => (
          <Typography key={key}>
            {key}: {cluster?.faults?.[key]}
          </Typography>
        ))}
      <Typography>Created at: {cluster?.created_at}</Typography>
      <Typography>Updated at: {cluster?.updated_at}</Typography>
      <Typography>Cluster template: {cluster?.labels.kube_version}</Typography>
    </>,
    <>
      <Typography>Master count: {cluster?.master_count}</Typography>
      <Typography>Node count: {cluster?.node_count}</Typography>
      <Typography>API address: {cluster?.api_address}</Typography>
      <Typography>
        Master addresses: {cluster?.master_addresses.join(", ")}
      </Typography>
      <Typography>
        Node addressess: {cluster?.node_addresses.join(", ")}
      </Typography>
    </>,
    <>
      <Typography>
        Discovery URL:{" "}
        <Link
          href={cluster?.discovery_url || "#"}
          rel={"noopener noreferrer"}
          target={"_blank"}
        >
          {cluster?.discovery_url}
        </Link>
      </Typography>
      <Typography>Cluster create timeout: {cluster?.create_timeout}</Typography>
      <Typography>Key pair: {cluster?.create_timeout}</Typography>
      <Typography>Master flavor: {masterFlavor}</Typography>
      <Typography>Node flavor: {nodeFlavor}</Typography>
      <Typography>Health status: {cluster?.health_status}</Typography>
    </>,
    <>
      {cluster &&
        Object.keys(cluster.labels).map((key) => (
          <Typography key={key}>
            {key}: {cluster?.labels[key]}
          </Typography>
        ))}
    </>
  ];

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.EDIT]: {
      onConfirm: handleConfirmEditCluster,
      title: "Edit cluster",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "value",
          type: FIELD_TYPES.NUMBER,
          defaultValue: cluster ? cluster.node_count : MIN_NODE_COUNT,
          label: "Node count",
          min: MIN_NODE_COUNT,
          rules: number()
            .integer()
            .min(
              MIN_NODE_COUNT,
              `Node count must be greater than or equals to ${MIN_NODE_COUNT}.`
            )
            .required()
        }
      ]
    },
    [DIALOG_TYPES.UPGRADE]: {
      onConfirm: handleConfirmUpgradeCluster,
      title: "Upgrade cluster",
      confirmButtonLabel: "Upgrade",
      isLocked: false,
      fields: [
        {
          name: "cluster_template",
          type: FIELD_TYPES.SELECT,
          label: "Cluster template",
          helperText:
            "✍ You can choose the current version to re-apply the configuration.",
          options: () => {
            const currentTemplateName = cluster?.labels.kube_tag;
            return currentTemplateName
              ? filterVersions(
                  currentTemplateName,
                  clusterTemplates,
                  (obj) => obj.name
                ).map(({ name, uuid }) => ({
                  label:
                    name === currentTemplateName ? `${name}  (current)` : name,
                  value: uuid
                }))
              : null;
          },
          rules: selectOptionSchema
        }
      ]
    },
    [DIALOG_TYPES.DELETE]: {
      onConfirm: handleConfirmDeleteCluster,
      title: `Are you sure you want to delete "${
        cluster?.name ?? "selected"
      }" cluster?`,
      fields: [
        {
          name: "confirmationName",
          type: FIELD_TYPES.TEXT,
          label: "Type the cluster name to confirm deletion",
          rules: string()
            .required()
            .test({
              name: "validateConfirmationName",
              message: "Cluster name does not match",
              test: function (value) {
                return value === cluster?.name;
              }
            })
        }
      ],
      confirmButtonLabel: "Delete"
    }
  };

  const title = cluster?.name;

  return (
    <>
      <Head title={title} />
      {cluster ? (
        <>
          {organization && project && <Breadcrumbs breadcrumbs={breadcrumbs} />}
          <s.SummaryContainer>
            <s.SummaryColumn>
              <s.Title variant={"h4"} component={"h2"}>
                {title}
              </s.Title>
              <s.SummaryRow>
                <s.DetailsTitle>ID: </s.DetailsTitle>
                <s.DetailsInfoColored>{cluster.id}</s.DetailsInfoColored>
              </s.SummaryRow>
            </s.SummaryColumn>
            <s.ActionsContainer>
              <Tooltip title={"Edit"} placement={"top"} arrow>
                <span>
                  <IconButton
                    onClick={handleEditClusterButtonClick}
                    color={"inherit"}
                    // title={"Edit"}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={"Delete"} placement={"top"} arrow>
                <span>
                  <IconButton
                    onClick={handleDeleteClusterButtonClick}
                    color={"inherit"}
                    // title={"Delete"}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={"More actions"} placement={"top"} arrow>
                <span>
                  <IconButton
                    onClick={handleActionsMenuButtonClick}
                    color={"inherit"}
                    // title={"Actions"}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Menu
                isOpened={isActionsMenuOpened}
                onClose={handleActionsMenuClose}
                anchorEl={actionsMenuButtonRef.current}
                items={actions.map((action) => ({
                  label: action.label,
                  onClick: handleActionsMenuItemClick(action.handler)
                }))}
              />
            </s.ActionsContainer>
          </s.SummaryContainer>
          <Tabs value={activeTabIndex} onChange={handleChangeTab}>
            {Object.values(TAB_TITLES).map((tabTitle) => (
              <Tab key={tabTitle} label={tabTitle} />
            ))}
          </Tabs>
          <s.TabContentContainer variant={"outlined"}>
            {tabContent[activeTabIndex]}
          </s.TabContentContainer>
        </>
      ) : (
        <Loader text={"Loading data..."} />
      )}
      <FormDialog
        isOpened={dialog.isOpened}
        onCancel={handleCloseDialog}
        fields={dialogProps[dialog.type].fields}
        onConfirm={dialogProps[dialog.type].onConfirm}
        title={dialogProps[dialog.type].title}
        confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
      />
    </>
  );
};
