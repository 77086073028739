import actionCreatorFactory from "typescript-fsa";
import {
  ChangeDatabaseStatusParams,
  ChangeDatabaseStatusResponse,
  CreateBackupDatabaseParams,
  CreateBackupDatabaseResponse,
  CreateDatabaseParams,
  CreateDatabaseResponse,
  CreateReplicaDatabaseParams,
  CreateReplicaDatabaseResponse,
  CronBackupParams,
  CronBackupResponse,
  CronDeleteParams,
  CronDeleteResponse,
  DeleteDatabaseParams,
  DeleteDatabaseResponse,
  GetDBServiceParamsParams,
  GetDBServiceParamsResponse,
  GetDBServicesParamsParams,
  GetDBServicesParamsResponse,
  GetDatabaseBackupFilesResponse,
  GetDatabaseBackupStatusResponse,
  GetDatabaseCustomOptionsParams,
  GetDatabaseCustomOptionsResponse,
  GetDatabaseParams,
  GetDatabaseResponse,
  GetDatabasesParams,
  GetDatabasesResponse,
  GetDbaasQuotasParams,
  GetDbaasQuotasResponse,
  GetManagementUrlParams,
  GetManagementUrlResponse,
  GetReplicaDatabaseParams,
  GetReplicaDatabasesResponse,
  ResetDatabasePasswordParams,
  ResetDatabasePasswordResponse,
  RestoreDatabaseParams,
  RestoreDatabaseResponse,
  S3LinkDatabaseParams,
  S3LinkDatabaseResponse,
  S3UnlinkDatabaseParams,
  S3UnlinkDatabaseResponse,
  UpdateDatabaseCustomOptionsParams,
  UpdateDatabaseCustomOptionsResponse,
  UpdateDatabaseParams,
  UpdateDatabaseResponse
} from "./types";

const actionCreator = actionCreatorFactory("DATABASES");

export const getDatabase = actionCreator.async<
  GetDatabaseParams,
  GetDatabaseResponse,
  unknown
>("GET_DATABASE");

export const getDatabases = actionCreator.async<
  GetDatabasesParams,
  GetDatabasesResponse,
  unknown
>("GET_DATABASES");

export const getReplicaDatabases = actionCreator.async<
  GetReplicaDatabaseParams,
  GetReplicaDatabasesResponse,
  unknown
>("GET_REPLICA_DATABASES");

export const getDatabaseBackupStatus = actionCreator.async<
  GetDatabaseParams,
  GetDatabaseBackupStatusResponse,
  unknown
>("GET_DATABASE_BACKUP_STATUS");

export const getDatabaseBackupFiles = actionCreator.async<
  GetDatabaseParams,
  GetDatabaseBackupFilesResponse,
  unknown
>("GET_DATABASE_BACKUP_FILES");

export const getDatabaseCustomOptions = actionCreator.async<
  GetDatabaseCustomOptionsParams,
  GetDatabaseCustomOptionsResponse,
  unknown
>("GET_DATABASE_CUSTOM_OPTIONS");

export const createReplicaDatabase = actionCreator.async<
  CreateReplicaDatabaseParams,
  CreateReplicaDatabaseResponse,
  unknown
>("CREATE_REPLICA_DATABASE");

export const restoreDatabase = actionCreator.async<
  RestoreDatabaseParams,
  RestoreDatabaseResponse,
  unknown
>("RESTORE_DATABASE");

export const backupDatabase = actionCreator.async<
  CreateBackupDatabaseParams,
  CreateBackupDatabaseResponse,
  unknown
>("BACKUP_DATABASE");

export const cronBackup = actionCreator.async<
  CronBackupParams,
  CronBackupResponse,
  unknown
>("CRON_DATABASE");

export const cronDelete = actionCreator.async<
  CronDeleteParams,
  CronDeleteResponse,
  unknown
>("CRON_DELETE_DATABASE");

export const s3LinkDatabase = actionCreator.async<
  S3LinkDatabaseParams,
  S3LinkDatabaseResponse,
  unknown
>("LINK_DATABASE_TO_S3");

export const s3UnlinkDatabase = actionCreator.async<
  S3UnlinkDatabaseParams,
  S3UnlinkDatabaseResponse,
  unknown
>("UNLINK_DATABASE_FROM_S3");

export const updateDatabaseCustomOptions = actionCreator.async<
  UpdateDatabaseCustomOptionsParams,
  UpdateDatabaseCustomOptionsResponse,
  unknown
>("UPDATE_DATABASE_CUSTOM_OPTIONS");

export const createDatabase = actionCreator.async<
  CreateDatabaseParams,
  CreateDatabaseResponse,
  unknown
>("CREATE_DATABASE");

export const updateDatabase = actionCreator.async<
  UpdateDatabaseParams,
  UpdateDatabaseResponse,
  unknown
>("UPDATE_DATABASE");

export const deleteDatabase = actionCreator.async<
  DeleteDatabaseParams,
  DeleteDatabaseResponse,
  unknown
>("DELETE_DATABASE");

export const getManagementUrl = actionCreator.async<
  GetManagementUrlParams,
  GetManagementUrlResponse,
  unknown
>("GET_MANAGEMENT_URL");

export const getDbaasQuotas = actionCreator.async<
  GetDbaasQuotasParams,
  GetDbaasQuotasResponse,
  unknown
>("GET_DBAAS_QUOTAS");

export const getDBServiceParams = actionCreator.async<
  GetDBServiceParamsParams,
  GetDBServiceParamsResponse,
  unknown
>("GET_DB_SERVICE_PARAMS");

export const getDBServicesParams = actionCreator.async<
  GetDBServicesParamsParams,
  GetDBServicesParamsResponse,
  unknown
>("GET_DB_SERVICES_PARAMS");

export const changeDatabaseStatus = actionCreator.async<
  ChangeDatabaseStatusParams,
  ChangeDatabaseStatusResponse,
  unknown
>("CHANGE_DATABASE_STATUS");

export const resetDatabasePassword = actionCreator.async<
  ResetDatabasePasswordParams,
  ResetDatabasePasswordResponse,
  unknown
>("RESET_DATABASE_PASSWORD");

export const clear = actionCreator<void>("CLEAR");

export const clearDatabase = actionCreator<void>("CLEAR_DATABASE");

export const clearDatabaseResetPassword = actionCreator<void>(
  "CLEAR_DATABASE_RESET_PASSWORD"
);

export const clearReplicaDatabases = actionCreator<void>(
  "CLEAR_REPLICA_DATABASES"
);

export const clearBackupStatus = actionCreator<void>("CLEAR_BACKUP_STATUS");

export const clearBackupFiles = actionCreator<void>("CLEAR_BACKUP_FILES");
