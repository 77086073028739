import Typography, { TypographyProps } from "@mui/material/Typography";
import styled from "styled-components";

export const SummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled(Typography)<
  TypographyProps<"h2", { component: "h2" }>
>`
  margin-right: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
export const SummaryRow = styled.div`
  display: flex;
  align-items: center;
`;

export const DetailsTitle = styled.span`
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.primary.main
      : theme.palette.text.secondary};
  font-weight: 500;
  font-size: 0.9rem;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const DetailsInfo = styled.span`
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

export const DetailsInfoColored = styled(DetailsInfo)`
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.text.primary
      : theme.palette.primary.main};
  font-weight: 450;
  font-size: 0.9rem;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const ProjectsTitle = styled(Typography)<
  TypographyProps<"h3", { component: "h3" }>
>`
  margin-top: ${({ theme }) => theme.spacing(3)};
`;

export const ContactSupportLinkText = styled(Typography)`
  font-size: 0.625rem;
  font-weight: 500;
  line-height: 1.4em;
`;
