import { ArgumentScale, EventTracker } from "@devexpress/dx-react-chart";
import {
  ArgumentAxis,
  Chart,
  Tooltip as DXTooltip,
  Legend,
  LineSeries,
  Title,
  ValueAxis
} from "@devexpress/dx-react-chart-material-ui";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LaunchIcon from "@mui/icons-material/Launch";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { DatabasePasswordDialog } from "components/common/DatabasePasswordDialog";
import { FormDialog, selectOptionSchema } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps,
  SelectOption
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Loader } from "components/common/Loader";
import { Menu } from "components/common/Menu";
import { Table } from "components/common/Table";
import {
  TableColumn,
  TableRowActionsMenuItem
} from "components/common/Table/types";
import { scaleTime } from "d3-scale";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as databasesActions from "modules/databases/actions";
import {
  DBServiceParamsSelector,
  backupFilesSelector,
  backupStatusSelector,
  cronStatusSelector,
  databaseCustomOptionsSelector,
  databaseResetPasswordSelector,
  databaseSelector,
  isCronBackupDeletingSelector,
  isCronBackupUpdatingSelector,
  isDatabaseBackingUpSelector,
  isDatabaseDeletingSelector,
  isDatabaseGettingManagementUrlSelector,
  isDatabaseLoadingSelector,
  isDatabaseRestoringSelector,
  isDatabaseStatusChangingSelector,
  isDatabaseUpdatingSelector,
  tableReplicaDatabasesSelector
} from "modules/databases/selectors";
import {
  CHANGE_STATUS_TYPES,
  DATABASE_BACKUP_STATUSES,
  DATABASE_REPLICA_STATUSES,
  DATABASE_RESTORE_STATUSES,
  DATABASE_STATUSES,
  DatabaseCustomOptions,
  TableCronStatus,
  TableDatabaseBackupFiles,
  TableDatabaseCustomOptions,
  TableReplicaDatabase
} from "modules/databases/types";

import * as enterprisesActions from "modules/enterprises/actions";
import { organizationSelector } from "modules/enterprises/selectors";
import * as pollingActions from "modules/polling/actions";
import * as projectsActions from "modules/projects/actions";
import {
  organizationProjectsSelector,
  projectSelector
} from "modules/projects/selectors";
import {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { isNumber } from "typeGuards/isNumber";
import { isString } from "typeGuards/isString";
import { formatDate } from "utils/formatDate";
import { generateSearchString } from "utils/generateSearchString";
import { getParentPath } from "utils/getParentPath";
import { getSelectOption } from "utils/getSelectOption";
import { roundTo } from "utils/roundTo";
import { splitMultilineCommaSeparatedList } from "utils/splitMultilineCommaSeparatedList";
import { number, string } from "yup";
import {
  CLIENT_TIME_ZONE,
  DATE_FORMATS,
  ERROR_MESSAGES,
  MAX_DATABASE_REPLICAS_COUNT,
  MAX_PORT,
  MIN_PORT,
  REGEX,
  ROUTES
} from "../../constants";
import * as s from "./styles";
import { DIALOG_TYPES, TABS } from "./types";

const POLL_ID_PREFIX = "DATABASE";

const POLL_IDS = {
  database: "DATABASE",
  firewallRules: "FIREWALL_RULES",
  databases: "DATABASES",
  replicaDatabases: "REPLICA_DATABASES",
  databaseBackupStatus: "DATABASE_BACKUP_STATUS",
  databaseBackupFiles: "DATABASE_BACKUP_FILES",
  databaseCustomOptions: "DATABASE_CUSTOM_OPTIONS"
};

const TAB_TITLES: { [key in TABS]: string } = {
  [TABS.REPLICAS]: "Replicas",
  [TABS.CRON]: "Scheduler",
  [TABS.BACKUPS]: "Backups",
  [TABS.CUSTOM_OPTIONS]: "Custom options"
};

const replicasTableColumns: TableColumn<TableReplicaDatabase>[] = [
  { key: "name", label: "Name" },
  { key: "service_status", label: "Status" },
  { key: "mode", label: "Mode" },
  { key: "versionString", label: "Version" },
  { key: "ageString", label: "Created" }
];

const backupsTableColumns: TableColumn<TableDatabaseBackupFiles>[] = [
  { key: "file_name", label: "Name" },
  { key: "fileSize", label: "Size" }
];

const cronTableColumns: TableColumn<TableCronStatus>[] = [
  { key: "status", label: "Status" },
  { key: "dateString", label: "Started" }
];

const customOptionsTableColumns: TableColumn<TableDatabaseCustomOptions>[] = [
  { key: "name", label: "Name" },
  { key: "min_value", label: "Min value" },
  { key: "max_value", label: "Max value" },
  { key: "default_value", label: "Default value" },
  { key: "custom_value", label: "Custom value" }
];

const databasePasswordTextAfterReset =
  "Password has been successfully reset. Please save the password for further use.";

export const Database: FC = () => {
  const dispatch = useDispatch();
  const matchParams = useParams<{
    organizationId: string;
    regionId: string;
    projectId: string;
    serviceName: string;
    databaseId: string;
  }>();
  const history = useNavigate();
  const organization = useSelector(organizationSelector);
  const project = useSelector(projectSelector);
  const projects = useSelector(organizationProjectsSelector);
  const database = useSelector(databaseSelector);
  const backupStatus = useSelector(backupStatusSelector);
  const cronStatus = useSelector(cronStatusSelector);
  const backupFiles = useSelector(backupFilesSelector);
  const replicaDatabases = useSelector(tableReplicaDatabasesSelector);
  const databaseCustomOptions = useSelector(databaseCustomOptionsSelector);
  const DBServiceParams = useSelector(DBServiceParamsSelector);
  const [isDatabasePasswordDialogOpened, setIsDatabasePasswordDialogOpened] =
    useState(false);
  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.EDIT, isOpened: false });
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const databaseResetedPassword = useSelector(databaseResetPasswordSelector);
  const isDatabaseLoading = useSelector(isDatabaseLoadingSelector);
  const isDatabaseRestoring = useSelector(isDatabaseRestoringSelector);
  const isDatabaseBackingUp = useSelector(isDatabaseBackingUpSelector);
  const isDatabaseUpdating = useSelector(isDatabaseUpdatingSelector);
  const isDatabaseDeleting = useSelector(isDatabaseDeletingSelector);
  const isDatabaseGettingManagementUrl = useSelector(
    isDatabaseGettingManagementUrlSelector
  );
  const isDatabaseStatusChanging = useSelector(
    isDatabaseStatusChangingSelector
  );

  const isCronBackupUpdating = useSelector(isCronBackupUpdatingSelector);
  const isCronBackupDeleting = useSelector(isCronBackupDeletingSelector);

  const [isActionsMenuOpened, setIsActionsMenuOpened] =
    useState<boolean>(false);
  const actionsMenuButtonRef = useRef<HTMLButtonElement | null>(null);
  const [
    selectedDatabasePasswordDialogText,
    setSelectedDatabasePasswordDialogText
  ] = useState<string>(databasePasswordTextAfterReset);
  const activeTabIndexFromParam = Object.keys(TAB_TITLES).find(
    (key) =>
      TAB_TITLES[key] &&
      String(TAB_TITLES[key]).toLowerCase() ===
        new URLSearchParams(location.search).get("tab")
  );
  const [activeTabIndex, setActiveTabIndex] = useState(
    Number(activeTabIndexFromParam || TABS.REPLICAS) as TABS
  );
  const previousActiveTabIndex = usePrevious(activeTabIndex);

  const previousDatabaseId = usePrevious(matchParams.databaseId);

  const previousIsDatabaseLoading = usePrevious(isDatabaseLoading);

  const isOperationInProgress =
    isDatabaseUpdating ||
    isDatabaseDeleting ||
    isDatabaseGettingManagementUrl ||
    isDatabaseStatusChanging ||
    isCronBackupUpdating ||
    isCronBackupDeleting ||
    isDatabaseRestoring ||
    isDatabaseBackingUp;
  const previousIsOperationInProgress = usePrevious(isOperationInProgress);

  const latestCronRunStatus =
    backupStatus?.cron_runs && backupStatus.cron_runs.length > 0
      ? backupStatus.cron_runs[backupStatus.cron_runs.length - 1].status
      : undefined;

  const areBackupOrRestoreDisabled =
    database?.service_status !== DATABASE_STATUSES.ACTIVE ||
    ![DATABASE_RESTORE_STATUSES.COMPLETED, undefined].includes(
      backupStatus?.restore_status
    ) ||
    ![DATABASE_BACKUP_STATUSES.COMPLETED, undefined].includes(
      backupStatus?.backup_status
    ) ||
    ![DATABASE_BACKUP_STATUSES.COMPLETED, undefined].includes(
      latestCronRunStatus
    );

  const selectedReplicaDatabaseProjectId = replicaDatabases?.find(
    (pj) => pj.id === selectedItemId
  )?.project_id;

  const isCreateReplicaEnabled =
    database &&
    [
      DATABASE_REPLICA_STATUSES.PRIMARY,
      DATABASE_REPLICA_STATUSES.STANDALONE
    ].includes(database?.mode) &&
    [DATABASE_STATUSES.ACTIVE].includes(database?.service_status) &&
    (replicaDatabases?.length || 0) <= MAX_DATABASE_REPLICAS_COUNT;

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Projects",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: project?.name || "",
      url: generatePath(ROUTES.PROJECT, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: "Databases",
      url: generatePath(ROUTES.DATABASES, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: database?.name || "",
      url: generatePath(ROUTES.DATABASE, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        serviceName: matchParams.serviceName,
        databaseId: matchParams.databaseId
      })
    }
  ];

  const handleChangeTab = useCallback((e, value: number) => {
    setActiveTabIndex(value);
  }, []);

  const handleActionsMenuButtonClick = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      actionsMenuButtonRef.current = e.currentTarget;
      setIsActionsMenuOpened(!isActionsMenuOpened);
    },
    [isActionsMenuOpened]
  );

  const handleActionsMenuClose = useCallback(() => {
    setIsActionsMenuOpened(false);
  }, []);

  const handleActionsMenuItemClick = useCallback(
    (callback: () => void) => () => {
      setIsActionsMenuOpened(false);
      callback();
    },
    []
  );

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
  }, [dialog]);

  const handleChangeDatabaseStatusButtonClick = useCallback(
    (type: CHANGE_STATUS_TYPES) => () => {
      dispatch(
        databasesActions.changeDatabaseStatus.started({
          orgId: matchParams.organizationId!,
          projId: matchParams.projectId!,
          id: matchParams.databaseId!,
          serviceName: matchParams.serviceName!,
          type: type
        })
      );
    },
    [
      dispatch,
      matchParams.databaseId,
      matchParams.organizationId,
      matchParams.projectId,
      matchParams.serviceName
    ]
  );

  const handleS3UnlinkDatabaseButtonClick = useCallback(() => {
    dispatch(
      databasesActions.s3UnlinkDatabase.started({
        orgId: matchParams.organizationId!,
        projId: matchParams.projectId!,
        id: matchParams.databaseId!,
        serviceName: matchParams.serviceName!
      })
    );
  }, [
    dispatch,
    matchParams.databaseId,
    matchParams.organizationId,
    matchParams.projectId,
    matchParams.serviceName
  ]);

  const handleMgrLinkDatabaseButtonClick = useCallback(() => {
    dispatch(
      databasesActions.getManagementUrl.started({
        orgId: matchParams.organizationId!,
        projId: matchParams.projectId!,
        id: matchParams.databaseId!,
        serviceName: matchParams.serviceName!
      })
    );
  }, [
    dispatch,
    matchParams.databaseId,
    matchParams.organizationId,
    matchParams.projectId,
    matchParams.serviceName
  ]);

  const handleEditDatabaseButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.EDIT,
      isOpened: true
    });
  }, []);

  const handleDeleteDatabaseButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.DELETE,
      isOpened: true
    });
  }, []);

  const handleRestoreDatabaseButtonClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.RESTORE_BACKUP,
      isOpened: true
    });
  }, []);

  // edit custom option inrow
  const handleEditDatabaseCustomOptionsButtonClick = useCallback(
    (id: string) => {
      setSelectedItemId(id);
      setDialog({
        type: DIALOG_TYPES.EDIT_CUSTOM_OPTION,
        isOpened: true
      });
    },
    []
  );

  const handleCreateBackupButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE_BACKUP,
      isOpened: true
    });
  }, []);

  const handleS3LinkButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.LINK_S3,
      isOpened: true
    });
  }, []);

  const handleCreateReplicaButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE_REPLICA,
      isOpened: true
    });
  }, []);

  const handleCronButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CRON,
      isOpened: true
    });
  }, []);

  const handleCronDeleteButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.DELETE_CRON,
      isOpened: true
    });
  }, []);

  const handleDeleteReplicaMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE_REPLICA,
      isOpened: true
    });
  }, []);

  const handleResetPasswordDatabaseMenuItemClick = useCallback(() => {
    setSelectedItemId(matchParams.databaseId!);
    setSelectedDatabasePasswordDialogText(databasePasswordTextAfterReset);
    setDialog({
      type: DIALOG_TYPES.RESET_PASSWORD,
      isOpened: true
    });
  }, [matchParams.databaseId]);

  const actions = [
    ...(database?.service_status === DATABASE_STATUSES.ACTIVE &&
    database?.mode !== DATABASE_REPLICA_STATUSES.REPLICA
      ? [
          {
            label: "Reset password",
            handler: handleResetPasswordDatabaseMenuItemClick
          }
        ]
      : [])
  ];

  const backupsTableActions: TableRowActionsMenuItem<TableDatabaseBackupFiles>[] =
    [
      {
        label: "Restore",
        isDisabled: () => areBackupOrRestoreDisabled,
        handler: handleRestoreDatabaseButtonClick
      }
    ];

  const customOptionsTableActions: TableRowActionsMenuItem<TableDatabaseCustomOptions>[] =
    [
      {
        label: "Edit",
        handler: handleEditDatabaseCustomOptionsButtonClick
      }
    ];

  const replicaTableActions: TableRowActionsMenuItem<TableReplicaDatabase>[] = [
    {
      label: "Delete",
      handler: handleDeleteReplicaMenuItemClick
    }
  ];

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.database}`,
        action: databasesActions.getDatabase.started({
          orgId: matchParams.organizationId!,
          projId: matchParams.projectId!,
          id: matchParams.databaseId!,
          serviceName: matchParams.serviceName!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.databaseBackupStatus}`,
        action: databasesActions.getDatabaseBackupStatus.started({
          orgId: matchParams.organizationId!,
          projId: matchParams.projectId!,
          id: matchParams.databaseId!,
          serviceName: matchParams.serviceName!
        })
      })
    );
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
    dispatch(
      projectsActions.getProject.started({
        regionId: matchParams.regionId!,
        id: matchParams.projectId!
      })
    );
    dispatch(
      projectsActions.getOrganizationProjects.started({
        organizationId: matchParams.organizationId!
      })
    );
    dispatch(
      databasesActions.getDBServiceParams.started({
        serviceName: matchParams.serviceName!
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(enterprisesActions.clear());
    dispatch(projectsActions.clear());
    dispatch(databasesActions.clear());
  });

  useEffect(() => {
    if (previousDatabaseId !== matchParams.databaseId) {
      Object.values(POLL_IDS).forEach((id) => {
        dispatch(
          pollingActions.stopPolling({
            id: `${POLL_ID_PREFIX}/${id}`
          })
        );
      });
      dispatch(enterprisesActions.clear());
      dispatch(projectsActions.clear());
      dispatch(databasesActions.clear());
      dispatch(
        pollingActions.startPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.database}`,
          action: databasesActions.getDatabase.started({
            orgId: matchParams.organizationId!,
            projId: matchParams.projectId!,
            id: matchParams.databaseId!,
            serviceName: matchParams.serviceName!
          })
        })
      );
      dispatch(
        pollingActions.startPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.databaseBackupStatus}`,
          action: databasesActions.getDatabaseBackupStatus.started({
            orgId: matchParams.organizationId!,
            projId: matchParams.projectId!,
            id: matchParams.databaseId!,
            serviceName: matchParams.serviceName!
          })
        })
      );
      dispatch(
        enterprisesActions.getOrganization.started({
          id: matchParams.organizationId!
        })
      );
      dispatch(
        projectsActions.getProject.started({
          regionId: matchParams.regionId!,
          id: matchParams.projectId!
        })
      );
      dispatch(
        projectsActions.getOrganizationProjects.started({
          organizationId: matchParams.organizationId!
        })
      );
      dispatch(
        databasesActions.getDBServiceParams.started({
          serviceName: matchParams.serviceName!
        })
      );
    }
  }, [
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    matchParams.organizationId,
    matchParams.databaseId,
    matchParams.serviceName,
    previousDatabaseId
  ]);

  useEffect(() => {
    if (previousIsOperationInProgress && !isOperationInProgress) {
      dispatch(
        databasesActions.getDatabase.started({
          orgId: matchParams.organizationId!,
          projId: matchParams.projectId!,
          serviceName: matchParams.serviceName!,
          id: matchParams.databaseId!
        })
      );
      dispatch(
        databasesActions.getDatabaseBackupStatus.started({
          orgId: matchParams.organizationId!,
          projId: matchParams.projectId!,
          serviceName: matchParams.serviceName!,
          id: matchParams.databaseId!
        })
      );
    }
  }, [
    database,
    history,
    previousIsOperationInProgress,
    isOperationInProgress,
    dispatch,
    matchParams.projectId,
    matchParams.organizationId,
    matchParams.databaseId,
    matchParams.serviceName
  ]);

  useEffect(() => {
    if (
      previousIsDatabaseLoading &&
      !isDatabaseLoading &&
      database?.service_status === DATABASE_STATUSES.DELETING
    ) {
      history(getParentPath(location.pathname, 2));
    }
  }, [database, history, previousIsDatabaseLoading, isDatabaseLoading]);

  useEffect(() => {
    if (
      previousActiveTabIndex !== activeTabIndex ||
      previousDatabaseId !== matchParams.databaseId
    ) {
      dispatch(databasesActions.clearBackupFiles());
      dispatch(databasesActions.clearReplicaDatabases());
      switch (activeTabIndex) {
        case TABS.REPLICAS:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.REPLICAS]
            })
          });
          pollingActions.stopPolling({
            id: `${POLL_ID_PREFIX}/${POLL_IDS.replicaDatabases}`
          });
          dispatch(
            pollingActions.startPolling({
              id: `${POLL_ID_PREFIX}/${POLL_IDS.replicaDatabases}`,
              action: databasesActions.getReplicaDatabases.started({
                orgId: matchParams.organizationId!,
                projId: matchParams.projectId!,
                id: matchParams.databaseId!,
                serviceName: matchParams.serviceName!
              })
            })
          );
          break;
        case TABS.CRON:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.CRON]
            })
          });
          break;
        case TABS.BACKUPS:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.BACKUPS]
            })
          });
          break;
        case TABS.CUSTOM_OPTIONS:
          history({
            search: generateSearchString({
              tab: TAB_TITLES[TABS.CUSTOM_OPTIONS]
            })
          });
          break;
      }
    }
  }, [
    previousDatabaseId,
    previousActiveTabIndex,
    activeTabIndex,
    backupStatus,
    backupFiles,
    history,
    dispatch,
    matchParams.projectId,
    matchParams.organizationId,
    matchParams.regionId,
    matchParams.databaseId,
    matchParams.serviceName
  ]);

  useEffect(() => {
    if (backupStatus?.link_exist && activeTabIndex === TABS.BACKUPS) {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.databaseBackupFiles}`
        })
      );
      dispatch(
        pollingActions.startPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.databaseBackupFiles}`,
          action: databasesActions.getDatabaseBackupFiles.started({
            orgId: matchParams.organizationId!,
            projId: matchParams.projectId!,
            id: matchParams.databaseId!,
            serviceName: matchParams.serviceName!
          })
        })
      );
    }
  }, [
    activeTabIndex,
    backupStatus,
    dispatch,
    matchParams.projectId,
    matchParams.organizationId,
    matchParams.databaseId,
    matchParams.serviceName
  ]);

  useEffect(() => {
    if (activeTabIndex === TABS.CUSTOM_OPTIONS) {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.databaseCustomOptions}`
        })
      );
      dispatch(
        pollingActions.startPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.databaseCustomOptions}`,
          action: databasesActions.getDatabaseCustomOptions.started({
            orgId: matchParams.organizationId!,
            projId: matchParams.projectId!,
            id: matchParams.databaseId!,
            serviceName: matchParams.serviceName!
          })
        })
      );
    }
  }, [
    activeTabIndex,
    dispatch,
    matchParams.projectId,
    matchParams.organizationId,
    matchParams.databaseId,
    matchParams.serviceName
  ]);

  useEffect(() => {
    setIsDatabasePasswordDialogOpened(true);
  }, [databaseResetedPassword]);

  const handleConfirmEditDatabase = useCallback(
    (data: {
      ipACL: string;
      cpu: SelectOption;
      memory: SelectOption;
      port: string;
      version: SelectOption;
    }) => {
      dispatch(
        databasesActions.updateDatabase.started({
          orgId: matchParams.organizationId!,
          projId: matchParams.projectId!,
          serviceName: matchParams.serviceName!,
          id: matchParams.databaseId!,
          data: {
            version: data.version.value,
            cpu: Number(data.cpu.value),
            memory: Number(data.memory.value),
            port: Number(data.port),
            ip_acl: splitMultilineCommaSeparatedList(data.ipACL)
          }
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      matchParams.organizationId,
      matchParams.projectId,
      matchParams.serviceName,
      matchParams.databaseId,
      handleCloseDialog
    ]
  );

  const handleEditDatabaseCustomOptions = useCallback(
    (value: string) => {
      dispatch(
        databasesActions.updateDatabaseCustomOptions.started({
          orgId: matchParams.organizationId!,
          projId: matchParams.projectId!,
          id: matchParams.databaseId!,
          serviceName: matchParams.serviceName!,
          data: [
            {
              name: selectedItemId || "",
              value: String(value)
            }
          ]
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      matchParams.organizationId,
      matchParams.projectId,
      matchParams.serviceName,
      matchParams.databaseId,
      selectedItemId,
      handleCloseDialog
    ]
  );
  const handleS3LinkDatabase = useCallback(
    (data: { endpoint: string; key: string; secret: string }) => {
      dispatch(
        databasesActions.s3LinkDatabase.started({
          orgId: matchParams.organizationId!,
          projId: matchParams.projectId!,
          serviceName: matchParams.serviceName!,
          id: matchParams.databaseId!,
          data: {
            endpoint: data.endpoint,
            key: data.key,
            secret: data.secret
          }
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      matchParams.organizationId,
      matchParams.projectId,
      matchParams.serviceName,
      matchParams.databaseId,
      handleCloseDialog
    ]
  );

  const handleCreateReplicaDatabase = useCallback(
    (data: { replProjId: SelectOption }) => {
      dispatch(
        databasesActions.createReplicaDatabase.started({
          orgId: matchParams.organizationId!,
          projId: matchParams.projectId!,
          serviceName: matchParams.serviceName!,
          id: matchParams.databaseId!,
          data: {
            project_id: data.replProjId.value
          }
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      matchParams.organizationId,
      matchParams.projectId,
      matchParams.serviceName,
      matchParams.databaseId,
      handleCloseDialog
    ]
  );

  const handleCronBackupApply = useCallback(
    (data: { cron: string; retention: string }) => {
      dispatch(
        databasesActions.cronBackup.started({
          orgId: matchParams.organizationId!,
          projId: matchParams.projectId!,
          serviceName: matchParams.serviceName!,
          id: matchParams.databaseId!,
          data: {
            cron: data.cron,
            retention: data.retention,
            timezone: CLIENT_TIME_ZONE
          }
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      matchParams.organizationId,
      matchParams.projectId,
      matchParams.serviceName,
      matchParams.databaseId,
      handleCloseDialog
    ]
  );

  const handleCronDeleteBackup = useCallback(() => {
    dispatch(
      databasesActions.cronDelete.started({
        orgId: matchParams.organizationId!,
        projId: matchParams.projectId!,
        serviceName: matchParams.serviceName!,
        id: matchParams.databaseId!
      })
    );
    handleCloseDialog();
  }, [
    dispatch,
    matchParams.organizationId,
    matchParams.projectId,
    matchParams.serviceName,
    matchParams.databaseId,
    handleCloseDialog
  ]);

  const handleConfirmDeleteDatabase = useCallback(() => {
    dispatch(
      databasesActions.deleteDatabase.started({
        orgId: matchParams.organizationId!,
        projId: matchParams.projectId!,
        serviceName: matchParams.serviceName!,
        id: matchParams.databaseId!
      })
    );
    handleCloseDialog();
  }, [
    dispatch,
    matchParams.organizationId,
    matchParams.projectId,
    matchParams.serviceName,
    matchParams.databaseId,
    handleCloseDialog
  ]);

  const handleConfirmDeleteReplica = useCallback(() => {
    if (selectedItemId && selectedReplicaDatabaseProjectId) {
      dispatch(
        databasesActions.deleteDatabase.started({
          orgId: matchParams.organizationId!,
          projId: selectedReplicaDatabaseProjectId,
          serviceName: matchParams.serviceName!,
          id: selectedItemId
        })
      );
      handleCloseDialog();
    }
  }, [
    dispatch,
    matchParams.organizationId,
    matchParams.serviceName,
    selectedReplicaDatabaseProjectId,
    selectedItemId,
    handleCloseDialog
  ]);

  const handleRestoreDatabase = useCallback(() => {
    if (selectedItemId && backupFiles) {
      const file = backupFiles?.find((file) => file.etag === selectedItemId);
      if (file) {
        dispatch(
          databasesActions.restoreDatabase.started({
            orgId: matchParams.organizationId!,
            projId: matchParams.projectId!,
            serviceName: matchParams.serviceName!,
            id: matchParams.databaseId!,
            data: {
              file: file.file_name
            }
          })
        );
      }

      handleCloseDialog();
    }
  }, [
    dispatch,
    selectedItemId,
    backupFiles,
    matchParams.organizationId,
    matchParams.projectId,
    matchParams.serviceName,
    matchParams.databaseId,
    handleCloseDialog
  ]);

  const handleBackupDatabase = useCallback(() => {
    dispatch(
      databasesActions.backupDatabase.started({
        orgId: matchParams.organizationId!,
        projId: matchParams.projectId!,
        serviceName: matchParams.serviceName!,
        id: matchParams.databaseId!,
        data: {
          timezone: CLIENT_TIME_ZONE
        }
      })
    );
    handleCloseDialog();
  }, [
    dispatch,
    matchParams.organizationId,
    matchParams.projectId,
    matchParams.serviceName,
    matchParams.databaseId,
    handleCloseDialog
  ]);

  const handleConfirmDatabasePasswordDialog = useCallback(() => {
    dispatch(
      databasesActions.resetDatabasePassword.started({
        orgId: matchParams.organizationId!,
        projId: matchParams.projectId!,
        serviceName: matchParams.serviceName!,
        id: matchParams.databaseId!
      })
    );
    handleCloseDialog();
  }, [
    dispatch,
    matchParams.organizationId,
    matchParams.projectId,
    matchParams.serviceName,
    matchParams.databaseId,
    handleCloseDialog
  ]);

  const handleCloseDatabasePasswordDialog = useCallback(() => {
    setIsDatabasePasswordDialogOpened(false);
    dispatch(databasesActions.clearDatabaseResetPassword());
  }, [dispatch]);

  const getCustomOptionRules = (selectedOption?: DatabaseCustomOptions) => {
    if (!selectedOption) {
      return string().required();
    }
    const rules = string().required();

    if (selectedOption.type === "integer") {
      const rules = number().required();
      const minValue = parseInt(selectedOption.min_value, 10);
      const maxValue = parseInt(selectedOption.max_value, 10);

      return rules
        .min(minValue, `Minimum value is ${minValue}`)
        .max(maxValue, `Maximum value is ${maxValue}`);
    }

    return rules;
  };

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.EDIT]: {
      onConfirm: handleConfirmEditDatabase,
      title: "Edit database",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "version",
          type: FIELD_TYPES.SELECT,
          label: "Version",
          defaultValue:
            database && getSelectOption(database, "version", "version"),
          options: DBServiceParams?.versions.map((x) => ({
            label: x,
            value: x
          })),
          rules: selectOptionSchema
        },
        {
          name: "port",
          type: FIELD_TYPES.NUMBER,
          label: "Port",
          defaultValue: isNumber(database?.port) ? database?.port : MIN_PORT,
          min: MIN_PORT,
          max: MAX_PORT,
          rules: number()
            .integer()
            .min(
              MIN_PORT,
              `Port must be greater than or equals to ${MIN_PORT}.`
            )
            .max(MAX_PORT, `Port must be less than ${MAX_PORT}.`)
        },
        {
          name: "cpu",
          type: FIELD_TYPES.SELECT,
          label: "CPU",
          defaultValue: database && {
            label: `${database.cpuString} vCPU`,
            value: String(database.cpu)
          },
          options: DBServiceParams?.cpu.map((x) => ({
            label: `${Number(x) / 1000} vCPU`,
            value: x
          })),
          rules: selectOptionSchema
        },
        {
          name: "memory",
          type: FIELD_TYPES.SELECT,
          label: "RAM",
          defaultValue:
            database && getSelectOption(database, "memoryString", "memory"),
          options: DBServiceParams?.memory.map((x) => ({
            label: `${Number(x) / 1024} GiB`,
            value: x
          })),
          rules: selectOptionSchema
        },
        {
          name: "ipACL",
          type: FIELD_TYPES.MULTILINE_TEXT,
          label: "IP ACL",
          defaultValue:
            database?.ipACL && database.ipACL.length > 0
              ? database.ipACL.join(",\n")
              : "",
          rules: string().test(
            "IPACLValidFormat",
            'IP must have the valid format (e.g., "192.168.1.101/24") and one entry per line',
            (value) => {
              // Check if the value is empty or contains only whitespace
              if (!value || !value.trim()) {
                return true; // Empty value is allowed
              }
              // Split the value into individual records and validate each one
              return splitMultilineCommaSeparatedList(value).every((record) =>
                REGEX.IP_ADDRESS_WITH_NET_MASK.test(record)
              );
            }
          )
        }
      ]
    },
    [DIALOG_TYPES.DELETE]: {
      onConfirm: handleConfirmDeleteDatabase,
      title: `Are you sure you want to delete "${
        database?.name ?? "selected"
      }" database?`,
      fields: [
        {
          name: "confirmationName",
          type: FIELD_TYPES.TEXT,
          label: "Type the database name to confirm deletion",
          rules: string()
            .required()
            .test({
              name: "validateConfirmationName",
              message: "Database name does not match",
              test: function (value) {
                return value === database?.name;
              }
            })
        }
      ],
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.DELETE_REPLICA]: {
      onConfirm: handleConfirmDeleteReplica,
      title: `Are you sure you want to delete replica database?`,
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.RESTORE_BACKUP]: {
      onConfirm: handleRestoreDatabase,
      title: "Are you sure you want to restore backup?",
      confirmButtonLabel: "Restore"
    },
    [DIALOG_TYPES.CREATE_BACKUP]: {
      onConfirm: handleBackupDatabase,
      title: "Are you sure you want to create a new backup?",
      confirmButtonLabel: "Backup"
    },
    [DIALOG_TYPES.DELETE_CRON]: {
      onConfirm: handleCronDeleteBackup,
      title: "Are you sure you want to delete scheduler configuration?",
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.LINK_S3]: {
      onConfirm: handleS3LinkDatabase,
      title: "S3 storage linking",
      confirmButtonLabel: "Link",
      fields: [
        {
          name: "endpoint",
          type: FIELD_TYPES.TEXT,
          label: "Endpoint",
          rules: string()
            .required()
            .matches(
              REGEX.HTTP_HTTPS_ADDRESS,
              ERROR_MESSAGES.HTTP_HTTPS_ADDRESS
            )
        },
        {
          name: "key",
          type: FIELD_TYPES.TEXT,
          label: "Key",
          rules: string().required()
        },
        {
          name: "secret",
          type: FIELD_TYPES.TEXT,
          label: "Secret",
          rules: string().required()
        }
      ]
    },
    [DIALOG_TYPES.CREATE_REPLICA]: {
      onConfirm: handleCreateReplicaDatabase,
      title: "Create replica",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "replProjId",
          type: FIELD_TYPES.SELECT,
          label: "Select project",
          options: projects?.map((x) =>
            x.id === matchParams.projectId
              ? {
                  label: `${x.name}, ${x.region} (Current project)`,
                  value: x.id
                }
              : {
                  label: `${x.name}, ${x.region}`,
                  value: x.id
                }
          ),
          rules: selectOptionSchema
        }
      ]
    },
    [DIALOG_TYPES.CRON]: {
      onConfirm: handleCronBackupApply,
      title: "Scheduler configuration",
      confirmButtonLabel: "Apply",
      fields: [
        {
          name: "cron",
          type: FIELD_TYPES.TEXT,
          defaultValue: backupStatus?.cron_exist
            ? backupStatus.cron_config
            : "0 1 * * *",
          label: "Cron",
          rules: string().required()
        },
        {
          name: "retention",
          type: FIELD_TYPES.TEXT,
          defaultValue: backupStatus?.cron_retention
            ? backupStatus.cron_retention
            : "7d0h0m",
          label: "Retention",
          rules: string().required()
        }
      ]
    },
    [DIALOG_TYPES.RESET_PASSWORD]: {
      onConfirm: handleConfirmDatabasePasswordDialog,
      title: "Are you sure you want to reset password?",
      confirmButtonLabel: "Reset"
    },
    // edit custom option
    [DIALOG_TYPES.EDIT_CUSTOM_OPTION]: {
      onConfirm: (data: { name: string }) =>
        handleEditDatabaseCustomOptions(data.name),
      title: "Edit custom option",
      confirmButtonLabel: "Apply",
      fields: [
        {
          name: "name",
          type:
            (databaseCustomOptions &&
              databaseCustomOptions.find(
                (customOption) => customOption.name === selectedItemId
              )?.type) === "integer"
              ? FIELD_TYPES.NUMBER
              : FIELD_TYPES.TEXT,
          label: selectedItemId || "selected_option",
          defaultValue:
            (databaseCustomOptions &&
              (databaseCustomOptions.find(
                (customOption) => customOption.name === selectedItemId
              )?.custom_value ||
                databaseCustomOptions.find(
                  (customOption) => customOption.name === selectedItemId
                )?.default_value)) ||
            "",
          rules: getCustomOptionRules(
            databaseCustomOptions?.find(
              (customOption) => customOption.name === selectedItemId
            )
          )
        }
      ]
    }
  };

  const generateReplicaTableItemURL = useCallback(
    (id: string) => {
      const replicaRegionId = replicaDatabases?.find(
        (database) => database.id === id
      )?.region;
      const replicaProjectId = replicaDatabases?.find(
        (database) => database.id === id
      )?.project_id;
      const replicaServiceName = replicaDatabases
        ?.find((database) => database.id === id)
        ?.serviceName.toLowerCase();

      if (replicaRegionId && replicaProjectId && replicaServiceName) {
        return generatePath(ROUTES.DATABASE, {
          organizationId: matchParams.organizationId,
          regionId: replicaRegionId,
          projectId: replicaProjectId,
          serviceName: replicaServiceName,
          databaseId: id
        });
      }
    },
    [matchParams.organizationId, replicaDatabases]
  );

  const title = database?.name;

  const tabContent = [
    <Table<TableReplicaDatabase>
      key={"replicaTable"}
      isSearchEnabled={true}
      isSortingEnabled={true}
      rows={replicaDatabases || []}
      columns={replicasTableColumns}
      actions={replicaTableActions}
      isLoading={!replicaDatabases}
      toolbarItems={
        <Tooltip
          arrow
          title={
            !isCreateReplicaEnabled ? (
              <>
                Database must be in ACTIVE state. Database must be not a
                replica. Count of replicas must be less than{" "}
                {MAX_DATABASE_REPLICAS_COUNT}.
              </>
            ) : (
              ""
            )
          }
        >
          <span>
            <>
              <Button
                onClick={handleCreateReplicaButtonClick}
                variant={"contained"}
                disabled={!isCreateReplicaEnabled}
              >
                Create Replica
              </Button>
            </>
          </span>
        </Tooltip>
      }
      itemLink={{
        column: "name",
        getURL: generateReplicaTableItemURL
      }}
    />,
    <Table<TableCronStatus>
      key={"schedulerTable"}
      isSearchEnabled={true}
      isSortingEnabled={true}
      rows={cronStatus || []}
      columns={cronTableColumns}
      isLoading={!cronStatus}
      toolbarItems={
        <>
          <Tooltip
            arrow
            title={
              !backupStatus?.link_exist ? (
                <>Database is not linked to S3 storage</>
              ) : (
                ""
              )
            }
          >
            <span>
              <s.CronButton
                onClick={handleCronButtonClick}
                variant={"contained"}
                disabled={!backupStatus?.link_exist}
              >
                Configure
              </s.CronButton>
            </span>
          </Tooltip>
          <Tooltip
            arrow
            title={
              !backupStatus?.link_exist ? (
                <>Database is not linked to S3 storage</>
              ) : !backupStatus?.cron_exist ? (
                <>Database has no configured scheduler</>
              ) : (
                ""
              )
            }
          >
            <span>
              <Button
                onClick={handleCronDeleteButtonClick}
                variant={"contained"}
                disabled={!backupStatus?.cron_exist}
              >
                Delete
              </Button>
            </span>
          </Tooltip>
        </>
      }
    />,
    <Table<TableDatabaseBackupFiles>
      key={"backupTable"}
      isSearchEnabled={true}
      isSortingEnabled={true}
      rows={backupFiles || []}
      columns={backupsTableColumns}
      actions={backupsTableActions}
      isLoading={backupStatus?.link_exist && !backupFiles}
      toolbarItems={
        <Tooltip
          arrow
          title={
            areBackupOrRestoreDisabled ? (
              <>Database operation in progress</>
            ) : !backupStatus?.link_exist ? (
              <>Database is not linked to S3 storage</>
            ) : (
              ""
            )
          }
        >
          <span>
            <Button
              onClick={handleCreateBackupButtonClick}
              variant={"contained"}
              disabled={areBackupOrRestoreDisabled || !backupStatus?.link_exist}
            >
              Create backup
            </Button>
          </span>
        </Tooltip>
      }
    />,
    <Table<TableDatabaseCustomOptions>
      key={"customOptionsTable"}
      isSearchEnabled={true}
      isSortingEnabled={true}
      rows={databaseCustomOptions || []}
      columns={customOptionsTableColumns}
      actions={customOptionsTableActions}
      isLoading={!databaseCustomOptions}
    />
  ];

  const endDateMetric =
    database?.hw_load.load && database?.hw_load.load.length > 0
      ? database?.hw_load.load[database?.hw_load.load.length - 1].time_stamp
      : new Date().valueOf();

  const startDateMetric =
    endDateMetric - (database?.hw_load.range_minutes || 60) * 60 * 1000;

  const domain = () => [startDateMetric, endDateMetric];
  const argumentFormat = () => (tick: string) =>
    `${formatDate(new Date(tick), DATE_FORMATS.TIME)}`;
  const valueFormat = () => (tick: string) => `${tick} %`;

  return (
    <>
      <Head title={title} />
      {database ? (
        <>
          {organization && project && <Breadcrumbs breadcrumbs={breadcrumbs} />}
          <s.SummaryContainer>
            <s.SummaryColumn>
              <s.Title title={title} variant={"h4"} component={"h2"}>
                {title}
              </s.Title>
              {database ? (
                <>
                  <s.SummaryRow>
                    <s.DetailsTitle>ID: </s.DetailsTitle>
                    <s.DetailsInfoColored>{database.id}</s.DetailsInfoColored>
                    <s.DetailsTitle>User: </s.DetailsTitle>
                    <s.DetailsInfoColored>{database.user}</s.DetailsInfoColored>
                  </s.SummaryRow>
                  <s.SummaryRow>
                    <s.DetailsTitle>Connection string: </s.DetailsTitle>
                    <s.DetailsInfoColored>
                      {database.connectionString}
                    </s.DetailsInfoColored>
                  </s.SummaryRow>
                  <s.SummaryRow>
                    <s.DetailsTitle>Created: </s.DetailsTitle>
                    <s.DetailsInfoColored>
                      {database.ageString}
                    </s.DetailsInfoColored>
                  </s.SummaryRow>
                  <s.SummaryRow>
                    <s.DetailsTitle>Status: </s.DetailsTitle>
                    <s.Tag label={database.service_status} />
                    <s.DetailsTitle>Mode: </s.DetailsTitle>
                    <s.Tag label={database.mode} />
                    {replicaDatabases &&
                    database.mode === DATABASE_REPLICA_STATUSES.PRIMARY ? (
                      <>
                        <s.DetailsTitle>Replicas: </s.DetailsTitle>
                        <s.DetailsInfoColored>
                          {replicaDatabases?.length}/
                          {MAX_DATABASE_REPLICAS_COUNT}
                        </s.DetailsInfoColored>
                      </>
                    ) : (
                      ""
                    )}
                  </s.SummaryRow>
                  <s.SummaryRow>
                    <s.DetailsTitle>vCPU: </s.DetailsTitle>
                    <s.Tag label={database.cpuString} />
                    <s.DetailsTitle>Memory: </s.DetailsTitle>
                    <s.Tag label={database.memoryString} />
                  </s.SummaryRow>
                  <s.SummaryRow>
                    <s.DetailsTitle>Disk: </s.DetailsTitle>
                    <s.Tag
                      label={`${database.diskString} (Used:
                      ${roundTo(parseFloat(database.volumeCapacity), 2)}%)`}
                    />
                    {/* <s.DetailsInfo>
                      {database.diskString} (Used:{" "}
                      {roundTo(parseFloat(database.volumeCapacity), 2)}%)
                    </s.DetailsInfo> */}
                  </s.SummaryRow>
                  <s.SummaryRow>
                    <s.DetailsTitle>Backing up: </s.DetailsTitle>
                    <s.Tag label={backupStatus?.backup_status || "idle"} />
                    <s.DetailsTitle>Restoring: </s.DetailsTitle>
                    <s.Tag label={backupStatus?.restore_status || "idle"} />
                    <s.DetailsTitle>Scheduler: </s.DetailsTitle>
                    <s.Tag label={latestCronRunStatus || "none"} />
                  </s.SummaryRow>
                </>
              ) : (
                <Loader size={24} text={"Loading database status data..."} />
              )}
              <s.SummaryRow>
                {backupStatus ? (
                  <>
                    <s.SummaryRow>
                      <s.DetailsTitle>S3: </s.DetailsTitle>
                      <s.Tag
                        label={
                          backupStatus.link_exist ? " Linked" : " Unlinked"
                        }
                      />
                      {backupStatus.link_exist ? (
                        <>
                          <s.DetailsTitle>Endpoint: </s.DetailsTitle>
                          <s.DetailsInfoColored>
                            {backupStatus?.s3endpoint}
                          </s.DetailsInfoColored>
                          <s.DetailsTitle>Key: </s.DetailsTitle>
                          <s.DetailsInfoColored>
                            {backupStatus?.s3key}
                          </s.DetailsInfoColored>
                        </>
                      ) : (
                        ""
                      )}
                    </s.SummaryRow>
                  </>
                ) : (
                  <Loader size={24} text={"Loading backup status data..."} />
                )}
              </s.SummaryRow>
            </s.SummaryColumn>
            <s.ActionsContainer>
              <Tooltip
                arrow
                title={
                  backupStatus?.link_exist
                    ? "Unlink from S3 storage"
                    : "Link to S3 storage"
                }
              >
                <span>
                  <IconButton
                    onClick={
                      backupStatus?.link_exist
                        ? handleS3UnlinkDatabaseButtonClick
                        : handleS3LinkButtonClick
                    }
                    color={"inherit"}
                    // title={
                    //   backupStatus?.link_exist
                    //     ? "Unlink from S3 storage"
                    //     : "Link to S3 storage"
                    // }
                  >
                    {backupStatus?.link_exist ? <LinkOffIcon /> : <LinkIcon />}
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={"Start"} arrow>
                <span>
                  <IconButton
                    disabled={
                      database.service_status !== DATABASE_STATUSES.STOPPED
                    }
                    onClick={handleChangeDatabaseStatusButtonClick(
                      CHANGE_STATUS_TYPES.START
                    )}
                    color={"inherit"}
                    // title={"Start"}
                  >
                    <PlayArrowIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={"Stop"} arrow>
                <span>
                  <IconButton
                    disabled={
                      database.service_status !== DATABASE_STATUSES.ACTIVE
                    }
                    onClick={handleChangeDatabaseStatusButtonClick(
                      CHANGE_STATUS_TYPES.STOP
                    )}
                    color={"inherit"}
                    // title={"Stop"}
                  >
                    <StopIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={"Database management"} arrow>
                <span>
                  <IconButton
                    onClick={handleMgrLinkDatabaseButtonClick}
                    color={"inherit"}
                    // title={"Remote console"}
                  >
                    <LaunchIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={"Edit"} arrow>
                <span>
                  <IconButton
                    onClick={handleEditDatabaseButtonClick}
                    color={"inherit"}
                    // title={"Edit"}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={"Delete"} arrow>
                <span>
                  <IconButton
                    onClick={handleDeleteDatabaseButtonClick}
                    color={"inherit"}
                    // title={"Delete"}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
              {actions.length > 0 && (
                <Tooltip title={"Actions"} arrow>
                  <span>
                    <IconButton
                      onClick={handleActionsMenuButtonClick}
                      color={"inherit"}
                      // title={"Actions"}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              <Menu
                isOpened={isActionsMenuOpened}
                onClose={handleActionsMenuClose}
                anchorEl={actionsMenuButtonRef.current}
                items={actions.map((action) => ({
                  label: action.label,
                  onClick: handleActionsMenuItemClick(action.handler)
                }))}
              />
            </s.ActionsContainer>
          </s.SummaryContainer>
          <s.MetricsContainer variant={"outlined"}>
            <Chart height={250} data={database?.hw_load.load || []}>
              <Title text={"Usage"} />
              <ArgumentScale factory={scaleTime} modifyDomain={domain} />
              <ValueAxis
                showLine={true}
                showTicks={true}
                tickFormat={valueFormat}
              />
              <ArgumentAxis showLine={true} tickFormat={argumentFormat} />
              <LineSeries
                name={"Memory"}
                valueField={"memory_value"}
                argumentField={"time_stamp"}
              />
              <LineSeries
                name={"CPU"}
                valueField={"cpu_value"}
                argumentField={"time_stamp"}
              />
              <EventTracker />
              <DXTooltip />
              <Legend />
            </Chart>
          </s.MetricsContainer>
          <Tabs value={activeTabIndex} onChange={handleChangeTab}>
            {Object.values(TAB_TITLES).map((tabTitle) => (
              <Tab key={tabTitle} label={tabTitle} />
            ))}
          </Tabs>
          {tabContent[activeTabIndex]}
          <FormDialog
            isOpened={dialog.isOpened}
            onCancel={handleCloseDialog}
            fields={dialogProps[dialog.type].fields}
            onConfirm={dialogProps[dialog.type].onConfirm}
            title={dialogProps[dialog.type].title}
            confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
          />
          {databaseResetedPassword &&
            isString(databaseResetedPassword.password) && (
              <DatabasePasswordDialog
                isOpened={isDatabasePasswordDialogOpened}
                name={databaseResetedPassword.name}
                text={selectedDatabasePasswordDialogText}
                password={databaseResetedPassword.password}
                onClose={handleCloseDatabasePasswordDialog}
              />
            )}
        </>
      ) : (
        <Loader text={"Loading data..."} />
      )}
    </>
  );
};
