import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LeaveIcon from "@mui/icons-material/ExitToApp";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { ContactSupportDialog } from "components/common/ContactSupportDialog";
import { FormDialog, selectOptionSchema } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps,
  SelectOption
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Table } from "components/common/Table";
import {
  TableColumn,
  TableRowActionsMenuItem
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as enterprisesActions from "modules/enterprises/actions";
import {
  isOrganizationDeletingSelector,
  isOrganizationUpdatingSelector,
  organizationSelector
} from "modules/enterprises/selectors";
import { ROLES } from "modules/enterprises/types";
import * as pollingActions from "modules/polling/actions";
import * as projectsActions from "modules/projects/actions";
import {
  isProjectCreatingSelector,
  isProjectDeletingSelector,
  isProjectUpdatingSelector,
  organizationProjectsSelector
} from "modules/projects/selectors";
import { TableProject } from "modules/projects/types";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { formatRegion, parseGroupRegionMap } from "utils/formatRegion";
import { getParentPath } from "utils/getParentPath";
import { getProjectRegion } from "utils/getProjectRegion";
import { validateName } from "utils/validateName";
import { string } from "yup";
import { appConfig } from "../../appConfig";
import {
  ENTITY_NAME_LENGTH,
  ERROR_MESSAGES,
  PROJECT_NAME_LENGTH,
  REGEX,
  ROUTES
} from "../../constants";
import * as s from "./styles";
import { DIALOG_TYPES } from "./types";

const POLL_ID_PREFIX = "ORGANIZATION";

const POLL_IDS = {
  organizationProjects: "ORGANIZATION_PROJECTS",
  organization: "ORGANIZATION"
};

const groupRegionMap = parseGroupRegionMap(appConfig.groupRegionMap);

const sortedNotHiddenRegions: string[] = appConfig.availableRegions
  .sort((a, b) => {
    const isAInCurrentGroup =
      groupRegionMap.get(a)?.group === appConfig.regionGroup;
    const isBInCurrentGroup =
      groupRegionMap.get(b)?.group === appConfig.regionGroup;

    if (isAInCurrentGroup && !isBInCurrentGroup) {
      return -1; // a comes before b
    } else if (!isAInCurrentGroup && isBInCurrentGroup) {
      return 1; // b comes before a
    } else {
      return a.localeCompare(b); // sort alphabetically
    }
  })
  .filter((region) => appConfig.hiddenRegions.includes(region) === false);

const tableColumns: TableColumn<TableProject>[] = [
  { key: "name", label: "Name" },
  { key: "id", label: "ID" },
  { key: "region", label: "Region" }
];

export const Organization: FC = () => {
  const dispatch = useDispatch();
  const matchParams = useParams<{
    organizationId: string;
  }>();
  const history = useNavigate();
  const organization = useSelector(organizationSelector);
  const projects = useSelector(organizationProjectsSelector);
  const isOrganizationUpdating = useSelector(isOrganizationUpdatingSelector);
  const isOrganizationDeleting = useSelector(isOrganizationDeletingSelector);
  const isOperationInProgress =
    isOrganizationUpdating || isOrganizationDeleting;
  const previousIsOperationInProgress = usePrevious(isOperationInProgress);
  const isProjectCreating = useSelector(isProjectCreatingSelector);
  const isProjectUpdating = useSelector(isProjectUpdatingSelector);
  const isProjectDeleting = useSelector(isProjectDeletingSelector);
  const isProjectOperationInProgress =
    isProjectCreating || isProjectUpdating || isProjectDeleting;
  const previousIsProjectOperationInProgress = usePrevious(
    isProjectOperationInProgress
  );
  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.EDIT_ORGANIZATION, isOpened: false });
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const [isContactSupportDialogOpened, setIsContactSupportDialogOpened] =
    useState(false);

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    }
  ];

  const generateTableItemURL = useCallback(
    (id: string) => {
      if (projects) {
        return generatePath(ROUTES.PROJECT, {
          organizationId: matchParams.organizationId,
          regionId: getProjectRegion(projects, id),
          projectId: id
        });
      }
    },
    [matchParams.organizationId, projects]
  );

  const handleCreateProjectButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE_PROJECT,
      isOpened: true
    });
  }, []);

  const handleEditProjectMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.EDIT_PROJECT,
      isOpened: true
    });
  }, []);

  const handleDeleteProjectMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE_PROJECT,
      isOpened: true
    });
  }, []);

  const tableActions: TableRowActionsMenuItem<TableProject>[] = [
    {
      label: "Edit",
      handler: handleEditProjectMenuItemClick,
      isDisabled: () => {
        return (
          !organization ||
          ![ROLES.OWNER, ROLES.ADMIN].includes(organization.role)
        );
      }
    },
    {
      label: "Delete",
      handler: handleDeleteProjectMenuItemClick,
      isDisabled: () => {
        return (
          !organization ||
          ![ROLES.OWNER, ROLES.ADMIN].includes(organization.role)
        );
      }
    }
  ];

  const handleConfirmCreateProject = useCallback(
    (data: { name: string; region: SelectOption }) => {
      dispatch(
        projectsActions.createProject.started({
          regionId: data.region.value,
          organizationId: matchParams.organizationId!,
          data
        })
      );
      handleCloseDialog();
    },
    [dispatch, matchParams.organizationId, handleCloseDialog]
  );

  const handleConfirmEditProject = useCallback(
    (data: { name: string }) => {
      if (projects && selectedItemId) {
        const regionId = getProjectRegion(projects, selectedItemId);
        if (regionId) {
          dispatch(
            projectsActions.updateProject.started({
              regionId,
              organizationId: matchParams.organizationId!,
              id: selectedItemId,
              data
            })
          );
        }
      }
      handleCloseDialog();
    },
    [
      dispatch,
      projects,
      selectedItemId,
      matchParams.organizationId,
      handleCloseDialog
    ]
  );

  const handleConfirmDeleteProject = useCallback(() => {
    if (projects && selectedItemId) {
      const regionId = getProjectRegion(projects, selectedItemId);
      if (regionId) {
        dispatch(
          projectsActions.deleteProject.started({
            regionId,
            organizationId: matchParams.organizationId!,
            id: selectedItemId
          })
        );
      }
      handleCloseDialog();
    }
  }, [
    projects,
    dispatch,
    selectedItemId,
    matchParams.organizationId,
    handleCloseDialog
  ]);

  const handleEditOrganizationButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.EDIT_ORGANIZATION,
      isOpened: true
    });
  }, []);

  const handleDeleteOrganizationButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.DELETE_ORGANIZATION,
      isOpened: true
    });
  }, []);

  const handleLeaveOrganizationButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.LEAVE_ORGANIZATION,
      isOpened: true
    });
  }, []);

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.organizationProjects}`,
        action: projectsActions.getOrganizationProjects.started({
          organizationId: matchParams.organizationId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.organization}`,
        action: enterprisesActions.getOrganization.started({
          id: matchParams.organizationId!
        })
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(enterprisesActions.clear());
    dispatch(projectsActions.clear());
  });

  useEffect(() => {
    if (previousIsOperationInProgress && !isOperationInProgress) {
      if (!organization) {
        history(getParentPath(location.pathname));
      } else {
        dispatch(
          enterprisesActions.getOrganization.started({
            id: matchParams.organizationId!
          })
        );
      }
    }
  }, [
    organization,
    history,
    previousIsOperationInProgress,
    isOperationInProgress,
    dispatch,
    matchParams.organizationId
  ]);

  useEffect(() => {
    if (previousIsProjectOperationInProgress && !isProjectOperationInProgress) {
      dispatch(
        projectsActions.getOrganizationProjects.started({
          organizationId: matchParams.organizationId!
        })
      );
    }
  }, [
    previousIsProjectOperationInProgress,
    isProjectOperationInProgress,
    dispatch,
    matchParams.organizationId
  ]);

  const handleConfirmEditOrganization = useCallback(
    (data: { name: string }) => {
      dispatch(
        enterprisesActions.updateOrganization.started({
          id: matchParams.organizationId!,
          data
        })
      );
      handleCloseDialog();
    },
    [dispatch, matchParams.organizationId, handleCloseDialog]
  );

  const handleConfirmDeleteOrganization = useCallback(() => {
    dispatch(
      enterprisesActions.deleteOrganization.started({
        id: matchParams.organizationId!
      })
    );
    handleCloseDialog();
  }, [dispatch, matchParams.organizationId, handleCloseDialog]);

  const handleConfirmLeaveOrganization = useCallback(() => {
    dispatch(
      enterprisesActions.leaveOrganization.started({
        organizationId: matchParams.organizationId!
      })
    );
    handleCloseDialog();
  }, [dispatch, matchParams.organizationId, handleCloseDialog]);

  const previousSelectedItemId = usePrevious(selectedItemId);
  const deletingItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const deletingProjectName = projects?.find(
    (project) => project.id === deletingItemId
  )?.name;

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.EDIT_ORGANIZATION]: {
      onConfirm: handleConfirmEditOrganization,
      title: "Edit organization",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue: organization?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
            .matches(REGEX.ORGANIZATION_NAME, ERROR_MESSAGES.ORGANIZATION_NAME)
        }
      ]
    },
    [DIALOG_TYPES.DELETE_ORGANIZATION]: {
      onConfirm: handleConfirmDeleteOrganization,
      title: "Are you sure you want to delete organization?",
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.LEAVE_ORGANIZATION]: {
      onConfirm: handleConfirmLeaveOrganization,
      title: "Are you sure you want to leave organization?",
      confirmButtonLabel: "Leave"
    },
    [DIALOG_TYPES.CREATE_PROJECT]: {
      onConfirm: handleConfirmCreateProject,
      title: "Create project",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(PROJECT_NAME_LENGTH)
            })
            .matches(REGEX.PROJECT_NAME, ERROR_MESSAGES.PROJECT_NAME)
        },
        {
          name: "region",
          type: FIELD_TYPES.GROUPED_SELECT,
          label: "Region",
          options: sortedNotHiddenRegions.map((region) => ({
            label: formatRegion(region),
            value: region
          })),
          groupMap: parseGroupRegionMap(appConfig.groupRegionMap),
          rules: selectOptionSchema
        }
      ]
    },
    [DIALOG_TYPES.EDIT_PROJECT]: {
      onConfirm: handleConfirmEditProject,
      title: "Edit project",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue:
            projects?.find((project) => project.id === selectedItemId)?.name ||
            "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(PROJECT_NAME_LENGTH)
            })
            .matches(REGEX.PROJECT_NAME, ERROR_MESSAGES.PROJECT_NAME)
        }
      ]
    },
    [DIALOG_TYPES.DELETE_PROJECT]: {
      onConfirm: handleConfirmDeleteProject,
      title: `Are you sure you want to delete "${
        deletingProjectName ?? "selected"
      }" project?`,
      fields: [
        {
          name: "confirmationName",
          type: FIELD_TYPES.TEXT,
          label: "Type the project name to confirm deletion",
          rules: string()
            .required()
            .test({
              name: "validateConfirmationName",
              message: "Project name does not match",
              test: function (value) {
                return value === deletingProjectName;
              }
            })
        }
      ],
      confirmButtonLabel: "Delete"
    }
  };

  const title = organization?.name;

  const projectLimit = Math.max(
    organization ? organization.project_number_limit : 0,
    appConfig.defaultAllowedProjectNumber
  );

  const isProjectLimitExceeded = Boolean(
    projects && projects.length >= projectLimit
  );

  const handleSupportLinkClick = useCallback(() => {
    setIsContactSupportDialogOpened(true);
  }, []);

  const handleContactSupportDialogClose = useCallback(() => {
    setIsContactSupportDialogOpened(false);
  }, []);

  return (
    <>
      <Head title={title} />
      {organization && (
        <>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <s.SummaryContainer>
            <s.Title variant={"h4"} component={"h2"}>
              {title}
            </s.Title>
            <s.ActionsContainer>
              <Tooltip title={"Edit"} arrow>
                <span>
                  <IconButton
                    onClick={handleEditOrganizationButtonClick}
                    color={"inherit"}
                    disabled={![ROLES.OWNER].includes(organization.role)}
                    // title={"Edit"}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={"Delete"} arrow>
                <span>
                  <IconButton
                    onClick={handleDeleteOrganizationButtonClick}
                    color={"inherit"}
                    disabled={![ROLES.OWNER].includes(organization.role)}
                    // title={"Delete"}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={"Leave"} arrow>
                <span>
                  <IconButton
                    onClick={handleLeaveOrganizationButtonClick}
                    color={"inherit"}
                    disabled={[ROLES.OWNER].includes(organization.role)}
                    // title={"Leave"}
                  >
                    <LeaveIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </s.ActionsContainer>
          </s.SummaryContainer>
          <s.SummaryRow>
            <s.DetailsTitle>ID: </s.DetailsTitle>
            <s.DetailsInfoColored>{organization.id}</s.DetailsInfoColored>
          </s.SummaryRow>
        </>
      )}
      <s.ProjectsTitle variant={"h5"} component={"h3"}>
        Projects
      </s.ProjectsTitle>
      <Table
        isSearchEnabled={true}
        isSortingEnabled={true}
        rows={projects || []}
        columns={tableColumns}
        actions={tableActions}
        itemLink={{
          column: "name",
          getURL: generateTableItemURL
        }}
        isLoading={!projects}
        toolbarItems={
          <Tooltip
            title={
              isProjectLimitExceeded ? (
                <>
                  Project count limit has been exceeded. Please contact{" "}
                  <Link component={"button"} onClick={handleSupportLinkClick}>
                    <s.ContactSupportLinkText>support</s.ContactSupportLinkText>
                  </Link>{" "}
                  to increase it.
                </>
              ) : (
                ""
              )
            }
          >
            <span>
              <Button
                onClick={handleCreateProjectButtonClick}
                variant={"contained"}
                disabled={
                  !projects ||
                  !organization ||
                  isProjectLimitExceeded ||
                  ![ROLES.OWNER, ROLES.ADMIN].includes(organization.role)
                }
              >
                Create project
              </Button>
            </span>
          </Tooltip>
        }
      />
      <FormDialog
        isOpened={dialog.isOpened}
        fields={dialogProps[dialog.type].fields}
        onConfirm={dialogProps[dialog.type].onConfirm}
        onCancel={handleCloseDialog}
        title={dialogProps[dialog.type].title}
        confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
      />
      <ContactSupportDialog
        isOpened={isContactSupportDialogOpened}
        onClose={handleContactSupportDialogClose}
      />
    </>
  );
};
