export enum DIALOG_TYPES {
  EDIT,
  DELETE,
  RESET_PASSWORD,
  DELETE_REPLICA,
  RESTORE_BACKUP,
  CREATE_BACKUP,
  CREATE_REPLICA,
  CRON,
  DELETE_CRON,
  LINK_S3,
  EDIT_CUSTOM_OPTION
}

export enum TABS {
  REPLICAS,
  CRON,
  BACKUPS,
  CUSTOM_OPTIONS
}
