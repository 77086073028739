import * as auth from "modules/auth/reducers";
import * as billing from "modules/billing/reducers";
import * as clusters from "modules/clusters/reducers";
import * as databases from "modules/databases/reducers";
import * as enterprises from "modules/enterprises/reducers";
import * as instances from "modules/instances/reducers";
import * as networks from "modules/networks/reducers";
import * as news from "modules/news/reducers";
import * as nlp from "modules/nlp/reducers";
import * as notifications from "modules/notifications/reducers";
import * as objectStorage from "modules/objectStorage/reducers";
import * as polling from "modules/polling/reducers";
import * as projects from "modules/projects/reducers";
import * as security from "modules/security/reducers";
import * as storage from "modules/storage/reducers";
import { combineReducers } from "redux";

export type RootReducer = {
  auth: auth.Reducer;
  instances: instances.Reducer;
  clusters: clusters.Reducer;
  storage: storage.Reducer;
  networks: networks.Reducer;
  notifications: notifications.Reducer;
  projects: projects.Reducer;
  security: security.Reducer;
  enterprises: enterprises.Reducer;
  polling: polling.Reducer;
  billing: billing.Reducer;
  databases: databases.Reducer;
  objectStorage: objectStorage.Reducer;
  nlp: nlp.Reducer;
  news: news.Reducer;
};

export const rootReducer = combineReducers<RootReducer>({
  auth: auth.reducer,
  instances: instances.reducer,
  clusters: clusters.reducer,
  storage: storage.reducer,
  networks: networks.reducer,
  notifications: notifications.reducer,
  projects: projects.reducer,
  security: security.reducer,
  enterprises: enterprises.reducer,
  polling: polling.reducer,
  billing: billing.reducer,
  databases: databases.reducer,
  objectStorage: objectStorage.reducer,
  nlp: nlp.reducer,
  news: news.reducer
});
