import { AxiosResponse } from "axios";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { isPollStoppedSaga } from "modules/polling/sagas";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { getAxiosErrorMessage } from "utils/getAxiosErrorMessage";
import { appConfig } from "../../appConfig";
import { axiosInstance } from "../../axios";
import {
  DATABASE_METRIC_DEFAULT_DURATION,
  DATABASE_METRIC_DEFAULT_RANGE
} from "../../constants";
import * as actions from "./actions";
import {
  ChangeDatabaseStatusParams,
  ChangeDatabaseStatusResponse,
  CreateBackupDatabaseParams,
  CreateBackupDatabaseResponse,
  CreateDatabaseParams,
  CreateDatabaseResponse,
  CreateReplicaDatabaseParams,
  CreateReplicaDatabaseResponse,
  CronBackupParams,
  CronBackupResponse,
  CronDeleteParams,
  CronDeleteResponse,
  DeleteDatabaseParams,
  DeleteDatabaseResponse,
  GetDBServiceParamsParams,
  GetDBServiceParamsResponse,
  GetDBServicesParamsParams,
  GetDBServicesParamsResponse,
  GetDatabaseBackupFilesResponse,
  GetDatabaseBackupStatusResponse,
  GetDatabaseCustomOptionsParams,
  GetDatabaseCustomOptionsResponse,
  GetDatabaseParams,
  GetDatabaseResponse,
  GetDatabasesParams,
  GetDatabasesResponse,
  GetDbaasQuotasParams,
  GetDbaasQuotasResponse,
  GetManagementUrlParams,
  GetManagementUrlResponse,
  GetReplicaDatabaseParams,
  GetReplicaDatabasesResponse,
  ResetDatabasePasswordParams,
  ResetDatabasePasswordResponse,
  RestoreDatabaseParams,
  RestoreDatabaseResponse,
  S3LinkDatabaseParams,
  S3LinkDatabaseResponse,
  S3UnlinkDatabaseParams,
  S3UnlinkDatabaseResponse,
  UpdateDatabaseCustomOptionsParams,
  UpdateDatabaseCustomOptionsResponse,
  UpdateDatabaseParams,
  UpdateDatabaseResponse
} from "./types";
const serviceApiPath = `gotham-golem/method/api/v1/`;

export function* getDatabaseSaga(
  action: Action<GetDatabaseParams>
): SagaIterator<void> {
  try {
    const { serviceName, orgId, projId, id } = action.payload;
    const response: AxiosResponse<GetDatabaseResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}dbaas/${serviceName}/db/${orgId}/${projId}/${id}`,
      {
        params: {
          duration: DATABASE_METRIC_DEFAULT_DURATION,
          range: DATABASE_METRIC_DEFAULT_RANGE
        }
      }
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getDatabase.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getDatabase.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get database data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getDatabasesSaga(
  action: Action<GetDatabasesParams>
): SagaIterator<void> {
  try {
    const { orgId, projId } = action.payload;
    const response: AxiosResponse<GetDatabasesResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}list-all/${orgId}/${projId}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getDatabases.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getDatabases.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get databases data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getDatabaseCustomOptionsSaga(
  action: Action<GetDatabaseCustomOptionsParams>
): SagaIterator<void> {
  try {
    const { serviceName, orgId, projId, id } = action.payload;
    const response: AxiosResponse<GetDatabaseCustomOptionsResponse> =
      yield call(
        axiosInstance.get,
        `${serviceApiPath}dbaas/${serviceName}/db/${orgId}/${projId}/${id}/custom-parameters`
      );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getDatabaseCustomOptions.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getDatabaseCustomOptions.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get database custom options data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* updateDatabaseCustomOptionsSaga(
  action: Action<UpdateDatabaseCustomOptionsParams>
): SagaIterator<void> {
  try {
    const { serviceName, orgId, projId, id, data } = action.payload;
    yield put(
      notificationsActions.showNotification({
        title: "Database custom option is updating.",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const response: AxiosResponse<UpdateDatabaseCustomOptionsResponse> =
      yield call(
        axiosInstance.put,
        `${serviceApiPath}dbaas/${serviceName}/db/${orgId}/${projId}/${id}/custom-parameters`,
        data
      );
    yield put(
      actions.updateDatabaseCustomOptions.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Database custom option have been successfully applied.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.updateDatabaseCustomOptions.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update database custom options",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createReplicaDatabaseSaga(
  action: Action<CreateReplicaDatabaseParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating database replica...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { serviceName, orgId, projId, id, data } = action.payload;
    const response: AxiosResponse<CreateReplicaDatabaseResponse> = yield call(
      axiosInstance.post,
      `${serviceApiPath}dbaas/${serviceName}/db/${orgId}/${projId}/${id}/replicas`,
      data
    );
    yield put(
      actions.createReplicaDatabase.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Replica database has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createReplicaDatabase.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create database replica",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getReplicaDatabaseSaga(
  action: Action<GetReplicaDatabaseParams>
): SagaIterator<void> {
  try {
    const { serviceName, orgId, projId, id } = action.payload;
    const response: AxiosResponse<GetReplicaDatabasesResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}dbaas/${serviceName}/db/${orgId}/${projId}/${id}/replicas`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getReplicaDatabases.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getReplicaDatabases.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get replica database data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getDbaasQuotasSaga(
  action: Action<GetDbaasQuotasParams>
): SagaIterator<void> {
  if (appConfig.isDbaasEnabled) {
    try {
      const { serviceName, orgId, projId } = action.payload;
      const response: AxiosResponse<GetDbaasQuotasResponse> = yield call(
        axiosInstance.get,
        `${serviceApiPath}dbaas/${serviceName}/db/${orgId}/${projId}/quotas`
      );
      const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
      if (!isPollStopped) {
        yield put(
          actions.getDbaasQuotas.done({
            params: action.payload,
            result: response.data
          })
        );
      }
    } catch (e) {
      yield put(
        actions.getDbaasQuotas.failed({ params: action.payload, error: e })
      );
      yield put(
        notificationsActions.showNotification({
          title: "Failed to get dbaas quotas data",
          text: getAxiosErrorMessage(e),
          type: NOTIFICATION_TYPES.ERROR
        })
      );
    }
  }
}

export function* createDatabaseSaga(
  action: Action<CreateDatabaseParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the database...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { serviceName, orgId, projId, data } = action.payload;
    const response: AxiosResponse<CreateDatabaseResponse> = yield call(
      axiosInstance.post,
      `${serviceApiPath}dbaas/${serviceName}/db/${orgId}/${projId}`,
      data
    );
    yield put(
      actions.createDatabase.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Database has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createDatabase.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create database",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* updateDatabaseSaga(
  action: Action<UpdateDatabaseParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Updating the database...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { serviceName, orgId, projId, id, data } = action.payload;
    const response: AxiosResponse<UpdateDatabaseResponse> = yield call(
      axiosInstance.put,
      `${serviceApiPath}dbaas/${serviceName}/db/${orgId}/${projId}/${id}`,
      data
    );
    yield put(
      actions.updateDatabase.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Database has been successfully updated.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.updateDatabase.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update database",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteDatabaseSaga(
  action: Action<DeleteDatabaseParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Deleting the database...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { serviceName, orgId, projId, id } = action.payload;
    const response: AxiosResponse<DeleteDatabaseResponse> = yield call(
      axiosInstance.delete,
      `${serviceApiPath}dbaas/${serviceName}/db/${orgId}/${projId}/${id}`
    );
    yield put(
      actions.deleteDatabase.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title:
          "Database has been successfully deleted. It might take a minute until all resources are removed.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteDatabase.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to delete database",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getDBServiceParamsSaga(
  action: Action<GetDBServiceParamsParams>
): SagaIterator<void> {
  try {
    const { serviceName } = action.payload;
    const response: AxiosResponse<GetDBServiceParamsResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}dbaas/${serviceName}/params`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getDBServiceParams.done({
          params: action.payload,
          result: response.data
        })
      );
    }
    yield put(
      actions.getDBServiceParams.done({
        params: action.payload,
        result: response.data
      })
    );
  } catch (e) {
    yield put(
      actions.getDBServiceParams.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get available service parameters",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getDBServicesParamsSaga(
  action: Action<GetDBServicesParamsParams>
): SagaIterator<void> {
  try {
    const response: AxiosResponse<GetDBServicesParamsResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}all-params`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getDBServicesParams.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getDBServicesParams.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get available service parameters",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* changeDatabaseStatusSaga(
  action: Action<ChangeDatabaseStatusParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Changing database status...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { serviceName, orgId, projId, id, type } = action.payload;
    const response: AxiosResponse<ChangeDatabaseStatusResponse> = yield call(
      axiosInstance.post,
      `${serviceApiPath}dbaas/${serviceName}/db/${orgId}/${projId}/${id}/actions/change-status`,
      null,
      {
        params: {
          type
        }
      }
    );
    yield put(
      actions.changeDatabaseStatus.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title:
          "Status of database has been successfully changed. It might take a minute until the process will be finished.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.changeDatabaseStatus.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to change database status",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* ResetDatabasePasswordSaga(
  action: Action<ResetDatabasePasswordParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Resetting the database password...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { serviceName, orgId, projId, id } = action.payload;
    const response: AxiosResponse<ResetDatabasePasswordResponse> = yield call(
      axiosInstance.put,
      `${serviceApiPath}dbaas/${serviceName}/db/${orgId}/${projId}/${id}/pwd-reset`
    );
    yield put(
      actions.resetDatabasePassword.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Database password has been successfully reset",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.resetDatabasePassword.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to reset database password",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getManagementUrlSaga(
  action: Action<GetManagementUrlParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Getting Management URL...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { serviceName, orgId, projId, id } = action.payload;
    const response: AxiosResponse<GetManagementUrlResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}dbaas/${serviceName}/db/${orgId}/${projId}/${id}/mgr`
    );
    yield put(
      actions.getManagementUrl.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Management URL has been successfully received.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
    window.open(response.data, "_blank");
  } catch (e) {
    yield put(
      actions.getManagementUrl.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get Management URL",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getDatabaseBackupStatusSaga(
  action: Action<GetDatabaseParams>
): SagaIterator<void> {
  try {
    const { serviceName, orgId, projId, id } = action.payload;
    const response: AxiosResponse<GetDatabaseBackupStatusResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}dbaas/${serviceName}/db/${orgId}/${projId}/${id}/backups/status`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getDatabaseBackupStatus.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getDatabaseBackupStatus.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get database backup status",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getDatabaseBackupFilesSaga(
  action: Action<GetDatabaseParams>
): SagaIterator<void> {
  try {
    const { serviceName, orgId, projId, id } = action.payload;
    const response: AxiosResponse<GetDatabaseBackupFilesResponse> = yield call(
      axiosInstance.get,
      `${serviceApiPath}dbaas/${serviceName}/db/${orgId}/${projId}/${id}/backups`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getDatabaseBackupFiles.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getDatabaseBackupFiles.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get database backup files",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* restoreDatabaseSaga(
  action: Action<RestoreDatabaseParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Starting database restoring...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { serviceName, orgId, projId, id, data } = action.payload;
    const response: AxiosResponse<RestoreDatabaseResponse> = yield call(
      axiosInstance.put,
      `${serviceApiPath}dbaas/${serviceName}/db/${orgId}/${projId}/${id}/backups/restore`,
      data
    );
    yield put(
      actions.restoreDatabase.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Database restoring has been started.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.restoreDatabase.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to start database restoring",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* cronBackupSaga(
  action: Action<CronBackupParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Backup scheduler applying...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { serviceName, orgId, projId, id, data } = action.payload;
    const response: AxiosResponse<CronBackupResponse> = yield call(
      axiosInstance.put,
      `${serviceApiPath}dbaas/${serviceName}/db/${orgId}/${projId}/${id}/backups/cron`,
      data
    );
    yield put(
      actions.cronBackup.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Backup scheduler applied.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(actions.cronBackup.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to apply scheduler configuration",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* cronDeleteSaga(
  action: Action<CronDeleteParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Backup scheduler deleting...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { serviceName, orgId, projId, id } = action.payload;
    const response: AxiosResponse<CronDeleteResponse> = yield call(
      axiosInstance.delete,
      `${serviceApiPath}dbaas/${serviceName}/db/${orgId}/${projId}/${id}/backups/cron`
    );
    yield put(
      actions.cronDelete.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Backup scheduler deleted.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(actions.cronDelete.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to delete scheduler configuration.",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* backupDatabaseSaga(
  action: Action<CreateBackupDatabaseParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Starting the database backing up...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { serviceName, orgId, projId, id, data } = action.payload;
    const response: AxiosResponse<CreateBackupDatabaseResponse> = yield call(
      axiosInstance.post,
      `${serviceApiPath}dbaas/${serviceName}/db/${orgId}/${projId}/${id}/backups`,
      data
    );
    yield put(
      actions.backupDatabase.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Database backing up has been started.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.backupDatabase.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to start database backing up",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* s3LinkDatabaseSaga(
  action: Action<S3LinkDatabaseParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Linking database to S3 storage...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { serviceName, orgId, projId, id, data } = action.payload;
    const response: AxiosResponse<S3LinkDatabaseResponse> = yield call(
      axiosInstance.put,
      `${serviceApiPath}dbaas/${serviceName}/db/${orgId}/${projId}/${id}/backups/link`,
      data
    );
    yield put(
      actions.s3LinkDatabase.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Database has been successfully linked to S3 storage.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.s3LinkDatabase.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to link to S3 storage",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* s3UnlinkDatabaseSaga(
  action: Action<S3UnlinkDatabaseParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Unlinking database from S3 storage...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { serviceName, orgId, projId, id } = action.payload;
    const response: AxiosResponse<S3UnlinkDatabaseResponse> = yield call(
      axiosInstance.delete,
      `${serviceApiPath}dbaas/${serviceName}/db/${orgId}/${projId}/${id}/backups/link`
    );
    yield put(
      actions.s3UnlinkDatabase.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Database has been successfully unlinked from S3 storage.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.s3UnlinkDatabase.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to unlink from S3 storage",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeLeading(actions.getDatabase.started, getDatabaseSaga),
    takeLeading(actions.getDatabases.started, getDatabasesSaga),
    takeLeading(actions.getReplicaDatabases.started, getReplicaDatabaseSaga),
    takeLeading(
      actions.getDatabaseBackupStatus.started,
      getDatabaseBackupStatusSaga
    ),
    takeLeading(
      actions.getDatabaseBackupFiles.started,
      getDatabaseBackupFilesSaga
    ),
    takeLeading(
      actions.getDatabaseCustomOptions.started,
      getDatabaseCustomOptionsSaga
    ),
    takeEvery(actions.createDatabase.started, createDatabaseSaga),
    takeEvery(actions.createReplicaDatabase.started, createReplicaDatabaseSaga),
    takeEvery(actions.updateDatabase.started, updateDatabaseSaga),
    takeEvery(
      actions.updateDatabaseCustomOptions.started,
      updateDatabaseCustomOptionsSaga
    ),
    takeEvery(actions.deleteDatabase.started, deleteDatabaseSaga),
    takeEvery(actions.getManagementUrl.started, getManagementUrlSaga),
    takeEvery(actions.restoreDatabase.started, restoreDatabaseSaga),
    takeEvery(actions.cronBackup.started, cronBackupSaga),
    takeEvery(actions.cronDelete.started, cronDeleteSaga),
    takeEvery(actions.backupDatabase.started, backupDatabaseSaga),
    takeEvery(actions.s3LinkDatabase.started, s3LinkDatabaseSaga),
    takeEvery(actions.s3UnlinkDatabase.started, s3UnlinkDatabaseSaga),
    takeLeading(actions.getDBServiceParams.started, getDBServiceParamsSaga),
    takeLeading(actions.getDbaasQuotas.started, getDbaasQuotasSaga),
    takeLeading(actions.getDBServicesParams.started, getDBServicesParamsSaga),
    takeEvery(actions.changeDatabaseStatus.started, changeDatabaseStatusSaga),
    takeEvery(actions.resetDatabasePassword.started, ResetDatabasePasswordSaga)
  ]);
}
