import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { FC, useEffect, useState } from "react";
import { roundTo } from "utils/roundTo";
import * as s from "./styles";
import { CircularProgressWithLabelProps } from "./types";

const ANIMATION_FREQUENCY = 50;
const ANIMATION_PERCENTAGE_STEP = 5;

export const CircularProgressWithLabel: FC<CircularProgressWithLabelProps> = ({
  value,
  maxValue,
  unit
}: CircularProgressWithLabelProps) => {
  const [progress, setProgress] = useState(0);
  const [timerId, setTimerId] = useState<number | null>(null);
  const percentageValue = (value / maxValue) * 100;

  useEffect(() => {
    const timerId = (window as Window).setInterval(() => {
      setProgress((prevProgress) =>
        Math.min(
          100,
          prevProgress +
            Math.min(ANIMATION_PERCENTAGE_STEP, percentageValue - prevProgress)
        )
      );
    }, ANIMATION_FREQUENCY);
    setTimerId(timerId);

    return () => {
      clearInterval(timerId);
    };
  }, [percentageValue]);

  useEffect(() => {
    if (timerId && progress === percentageValue) {
      clearInterval(timerId);
    }
  }, [progress, percentageValue, timerId]);

  return (
    <div>
      <Box position={"relative"} display={"inline-flex"}>
        <CircularProgress variant={"determinate"} value={progress} size={130} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position={"absolute"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <s.LabelContainer>
            <Typography
              variant={"h4"}
              component={"div"}
              color={"textSecondary"}
            >{`${Math.round(percentageValue)}%`}</Typography>
            <Typography
              variant={"caption"}
              component={"div"}
              color={"textSecondary"}
            >{`${roundTo(value, 2)} of ${roundTo(maxValue, 2)}${
              unit ? ` ${unit}` : ""
            }`}</Typography>
          </s.LabelContainer>
        </Box>
      </Box>
    </div>
  );
};
