import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Checkbox,
  FormControl,
  MenuItem,
  Pagination,
  Select
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableFooter from "@mui/material/TableFooter";
import TableHead from "@mui/material/TableHead";
import MuiTablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import { Menu } from "components/common/Menu";
import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { Link as RouterLink } from "react-router-dom";
import { isNull } from "typeGuards/isNull";
import { isNumber } from "typeGuards/isNumber";
import { isString } from "typeGuards/isString";
import { isUndefined } from "typeGuards/isUndefined";
import { ELEMENT_IDS, MAX_PAGINATION_LIMIT } from "../../../constants";
import { Loader } from "../Loader";
import * as s from "./styles";
import { ORDERS, TABLE_SORTING_TYPES, TableColumn, TableProps } from "./types";

const DEFAULT_ROWS_PER_PAGE_OPTIONS = [10, 50, 100, 500, MAX_PAGINATION_LIMIT];

export const Table = <
  T extends {
    status?: string;
    id: string;
  }
>({
  title,
  titleDetails,
  disabledRows,
  rows,
  columns,
  actions,
  isSelectingEnabled,
  multiActions,
  titleActions,
  tabs,
  toolbarItems,
  isLoading,
  itemLink,
  itemWithIcon,
  totalRow,
  isSearchEnabled,
  isRowSelectable,
  isPaginationEnabled,
  page,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
  isAdvancedPaginationEnabled,
  count,
  isNextPageAvailable,
  isSortingEnabled,
  onChangeSearch,
  searchString,
  resetCheckboxes,
  onResetCheckboxes
}: TableProps<T>): JSX.Element => {
  const [isActionsMenuOpened, setIsActionsMenuOpened] =
    useState<boolean>(false);
  const [sorting, setSorting] = useState<{
    column: string | null;
    order?: ORDERS;
  }>({ column: null });
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [search, setSearch] = useState("");

  const resetChackedRows = useCallback(() => {
    setCheckedRows([]);
    onResetCheckboxes && onResetCheckboxes();
  }, [onResetCheckboxes]);

  useEffect(() => {
    if (resetCheckboxes) {
      resetChackedRows();
    }
  }, [resetCheckboxes, resetChackedRows]);

  const selectedItem = !isNull(selectedItemId)
    ? rows.find((row) => row.id === selectedItemId)
    : undefined;

  const sortBy = useCallback(
    (column) => () => {
      setSorting({
        column,
        order:
          column !== sorting.column || sorting.order === ORDERS.DESC
            ? ORDERS.ASC
            : ORDERS.DESC
      });
    },
    [sorting]
  );

  let sortedRows = rows;
  const sortingColumn = sorting.column;

  if (sortingColumn) {
    sortedRows = [...rows].sort((a, b) => {
      let aValue = a[sortingColumn];
      let bValue = b[sortingColumn];

      const column = columns.find((column) => column.key === sortingColumn);
      if (
        column?.sortingType === TABLE_SORTING_TYPES.DATE &&
        isString(aValue) &&
        isString(bValue)
      ) {
        aValue = new Date(aValue).valueOf();
        bValue = new Date(bValue).valueOf();
      }

      if (
        column?.sortingType === TABLE_SORTING_TYPES.NUMBER &&
        isString(aValue) &&
        isString(bValue)
      ) {
        aValue = parseFloat(aValue);
        bValue = parseFloat(bValue);
      }

      if (sorting.order === ORDERS.ASC) {
        if (aValue < bValue) {
          return -1;
        }
        if (aValue > bValue) {
          return 1;
        }
      }

      if (sorting.order === ORDERS.DESC) {
        if (aValue > bValue) {
          return -1;
        }
        if (aValue < bValue) {
          return 1;
        }
      }

      return 0;
    });
  }

  const filteredRows = search
    ? sortedRows.filter((row) =>
        columns.some(
          (column) =>
            String(row[column.key])
              .toLowerCase()
              .indexOf(search.toLowerCase()) > -1
        )
      )
    : sortedRows;

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCheckedRows([]);
      if (onChangeSearch) {
        onChangeSearch(e);
      } else {
        setSearch(e.target.value);
      }
    },
    [onChangeSearch]
  );

  const handleActionsMenuButtonClick = useCallback(
    (id: string) => (e: MouseEvent<HTMLButtonElement>) => {
      actionsMenuButtonRef.current = e.currentTarget;
      setSelectedItemId(id);
      isActionsMenuOpened ? setCheckedRows([]) : setCheckedRows([id]);
      setIsActionsMenuOpened(!isActionsMenuOpened);
    },
    [isActionsMenuOpened]
  );

  const handleActionsMenuClose = useCallback(() => {
    setIsActionsMenuOpened(false);
  }, []);

  const handleActionsMenuItemClick = useCallback(
    (callback: (id: string) => void) => () => {
      if (selectedItemId) {
        setIsActionsMenuOpened(false);
        callback(selectedItemId);
      }
    },
    [selectedItemId]
  );

  const actionsMenuButtonRef = useRef<HTMLButtonElement | null>(null);

  const rowsPerPageOptions =
    isNumber(rowsPerPage) &&
    Number.isInteger(rowsPerPage) &&
    rowsPerPage > 0 &&
    !DEFAULT_ROWS_PER_PAGE_OPTIONS.includes(rowsPerPage)
      ? [...DEFAULT_ROWS_PER_PAGE_OPTIONS, rowsPerPage].sort((a, b) => a - b)
      : DEFAULT_ROWS_PER_PAGE_OPTIONS;

  const menuBoundariesElement = document.getElementById(
    ELEMENT_IDS.CONTENT_CONTAINER
  );

  // add multisilect
  const [selectAll, setSelectAll] = useState(false);
  const [checkedRows, setCheckedRows] = useState<string[]>([]);

  const handleSelectAll = (isChecked: boolean) => {
    if (isChecked) {
      const allIds = filteredRows
        .filter((row) => (isRowSelectable ? isRowSelectable(row) : true)) // check if row is selectable
        .map((row) => row.id);
      setCheckedRows(allIds);
    } else {
      setCheckedRows([]);
    }
  };

  const isChecked = (id: string) => checkedRows.indexOf(id) !== -1;

  const handleCheckboxChange =
    (id: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      setCheckedRows((prev) => {
        if (isChecked) {
          return [...prev, id];
        } else {
          return prev.filter((item) => item !== id);
        }
      });
    };

  useEffect(() => {
    const selectableRows = filteredRows.filter((row) =>
      isRowSelectable ? isRowSelectable(row) : true
    );
    if (
      selectableRows.length > 0 &&
      checkedRows.length === selectableRows.length
    ) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [checkedRows, filteredRows, isRowSelectable]);

  const handleMultiSelectActionClick = useCallback(
    (ids: string[], callback: (ids: string[]) => void) => {
      callback(ids);
      // setCheckedRows([]);
    },
    []
  );

  return (
    <s.Container>
      {(title || titleDetails) && (
        <s.TitleContainer>
          <s.SummaryColumn>
            {title && (
              <s.Title title={title} variant={"h4"} component={"h2"}>
                {title}
              </s.Title>
            )}
            {titleDetails}
          </s.SummaryColumn>
          <s.ActionsContainer>
            {titleActions &&
              titleActions.length > 0 &&
              titleActions.map((action) => {
                return (
                  !action.isDisabled && (
                    <Tooltip title={action.label} key={action.label} arrow>
                      <span>
                        <IconButton
                          onClick={() => action.handler(action.id)}
                          color={"inherit"}
                        >
                          {action.icon}
                        </IconButton>
                      </span>
                    </Tooltip>
                  )
                );
              })}
            {isSelectingEnabled &&
              multiActions?.map((action) => {
                return (
                  <Tooltip title={action.label} key={action.label} arrow>
                    <span>
                      <IconButton
                        onClick={() =>
                          handleMultiSelectActionClick(
                            checkedRows,
                            action.handler
                          )
                        }
                        disabled={checkedRows.length === 0}
                        color={"inherit"}
                      >
                        {action.icon}
                      </IconButton>
                    </span>
                  </Tooltip>
                );
              })}
          </s.ActionsContainer>
        </s.TitleContainer>
      )}
      {tabs && <s.TabsContainer>{tabs}</s.TabsContainer>}
      <s.Toolbar>
        {toolbarItems}
        {isSearchEnabled && (
          // <s.SearchContainer>
          <s.SearchTextField
            value={onChangeSearch ? searchString : search}
            label={"Search"}
            inputProps={{
              title: "Search"
            }}
            onChange={handleSearchChange}
            size={"small"}
          />
          // </s.SearchContainer>
        )}
      </s.Toolbar>
      <Paper variant="outlined">
        <s.Table stickyHeader data-testid={"table-container"}>
          <TableHead>
            <TableRow>
              {isSelectingEnabled && filteredRows.length > 0 && (
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectAll}
                    onChange={(event) => {
                      setSelectAll(event.target.checked);
                      handleSelectAll(event.target.checked);
                    }}
                    size="small"
                    color="primary"
                  />
                </TableCell>
              )}
              {columns.map((column: TableColumn<T>) => (
                <TableCell
                  key={String(column.key)}
                  data-column={String(column.label)}
                >
                  {isSortingEnabled ? (
                    <TableSortLabel
                      active={sorting.column === column.key}
                      direction={sorting.order}
                      onClick={sortBy(column.key)}
                    >
                      <s.TableCellText title={column.label}>
                        {column.label}
                      </s.TableCellText>
                    </TableSortLabel>
                  ) : (
                    <s.TableCellText title={column.label}>
                      {column.label}
                    </s.TableCellText>
                  )}
                </TableCell>
              ))}
              {actions && actions.length > 0 && <s.ActionsTableCell />}
            </TableRow>
          </TableHead>
          {!isLoading && (
            <>
              <TableBody data-testid={"table-body"}>
                {filteredRows.length > 0 ? (
                  filteredRows.map((row) => (
                    <TableRow key={row.id} data-testid={"table-row"}>
                      {isSelectingEnabled && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isChecked(row.id)}
                            onChange={handleCheckboxChange(row.id)}
                            size="small"
                            disabled={
                              isRowSelectable ? !isRowSelectable(row) : false
                            }
                          />
                        </TableCell>
                      )}

                      {columns.map((column) => (
                        <TableCell
                          data-testid={`table-cell-${String(column.key)}`}
                          key={String(column.key)}
                        >
                          {itemLink &&
                          (isUndefined(itemLink.isEnabled) ||
                            itemLink.isEnabled(row)) &&
                          itemLink.getURL(row.id) &&
                          itemLink.column === column.key ? (
                            <s.TableLink
                              component={RouterLink}
                              to={itemLink.getURL(row.id) || "#"}
                              title={String(row[column.key])}
                            >
                              {String(row[column.key])}
                            </s.TableLink>
                          ) : (
                            <>
                              {itemWithIcon &&
                              column.key === itemWithIcon.column &&
                              String(row[itemWithIcon.column]) ===
                                itemWithIcon.rowSpecificity ? (
                                <s.IconContainer>
                                  <Tooltip
                                    title={itemWithIcon.tooltipText}
                                    placement={"top"}
                                    arrow
                                  >
                                    {itemWithIcon.icon}
                                  </Tooltip>
                                  <s.TableCellText variant={"body2"}>
                                    {String(row[column.key])}
                                  </s.TableCellText>
                                </s.IconContainer>
                              ) : (
                                <s.TableCellText
                                  variant={"body2"}
                                  // title={String(row[column.key])}
                                >
                                  {String(row[column.key])}
                                </s.TableCellText>
                              )}
                            </>
                          )}
                        </TableCell>
                      ))}

                      {actions && actions.length && (
                        <s.ActionsTableCell
                          padding={"checkbox"}
                          data-testid={"table-row-actions-menu-button"}
                        >
                          {actions.some(
                            (action) =>
                              !action.isDisabled || !action.isDisabled(row)
                          ) && (
                            <Tooltip title={"Actions"} placement={"top"} arrow>
                              <span>
                                <IconButton
                                  onClick={handleActionsMenuButtonClick(row.id)}
                                  // title={"Actions"}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              </span>
                            </Tooltip>
                          )}
                        </s.ActionsTableCell>
                      )}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length + (actions ? 1 : 0)}
                      data-testid={"table-cell-no-data"}
                    >
                      <s.NoDataText data-testid={"no-data-text"}>
                        No data
                      </s.NoDataText>
                    </TableCell>
                  </TableRow>
                )}
                {disabledRows &&
                  disabledRows.length > 0 &&
                  disabledRows.map((row) => (
                    <TableRow
                      key={row.id}
                      data-testid={"table-row"}
                      title={"Deprecated"}
                    >
                      {isSelectingEnabled && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isChecked(row.id)}
                            onChange={handleCheckboxChange(row.id)}
                            size="small"
                            disabled={
                              isRowSelectable ? !isRowSelectable(row) : false
                            }
                          />
                        </TableCell>
                      )}
                      {columns.map((column) => (
                        <TableCell
                          data-testid={`table-cell-${String(column.key)}`}
                          key={String(column.key)}
                        >
                          <s.TableCellTextDisabled>
                            {String(row[column.key])}
                          </s.TableCellTextDisabled>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
              {totalRow && (
                <TableFooter>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={String(column.key)}>
                        <s.TableTotalCellText
                        // title={String(totalRow[column.key])}
                        >
                          {String(totalRow[column.key])}
                        </s.TableTotalCellText>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableFooter>
              )}
            </>
          )}
        </s.Table>
        {isLoading && (
          <s.LoaderContainer>
            <Loader text={"Loading data..."} />
          </s.LoaderContainer>
        )}
        {isPaginationEnabled &&
          onChangePage &&
          onChangeRowsPerPage &&
          rowsPerPage &&
          !isLoading &&
          isNumber(page) &&
          isNumber(count) && (
            <s.PaginationContainer>
              {isAdvancedPaginationEnabled ? (
                <>
                  <s.Description>Rows per page:</s.Description>
                  <FormControl>
                    <Select
                      // label="Rows per page"
                      sx={{ marginRight: "40px", fontSize: "small" }}
                      size="small"
                      variant="outlined"
                      value={rowsPerPage}
                      defaultValue={rowsPerPageOptions[0]}
                      onChange={onChangeRowsPerPage}
                      displayEmpty
                    >
                      {rowsPerPageOptions.map((option) => (
                        <MenuItem
                          key={option}
                          value={option}
                          sx={{ fontSize: "small" }}
                        >
                          {`${option}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Pagination
                    count={
                      isNextPageAvailable
                        ? Math.ceil(count / rowsPerPage) + 1
                        : Math.ceil(count / rowsPerPage)
                    }
                    page={page + 1}
                    onChange={(event, newPage) =>
                      onChangePage(event, newPage - 1)
                    }
                    showFirstButton
                  />
                </>
              ) : (
                <MuiTablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component={s.TablePagination}
                  showFirstButton={true}
                  showLastButton={true}
                  count={count}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={onChangePage}
                  onRowsPerPageChange={onChangeRowsPerPage}
                  sx={{ margin: "0" }}
                  slotProps={{ select: { variant: "outlined", size: "small" } }}
                  // labelDisplayedRows={({ from, to, count }) =>
                  //   `Page: ${Math.floor(
                  //     rows.length / rowsPerPage
                  //   )} shown: ${from}-${to} of ${count} elements`
                  // }
                />
              )}
            </s.PaginationContainer>
          )}
      </Paper>
      <Menu
        MenuListProps={{ "data-testid": "table-row-actions-menu-list" }}
        isOpened={isActionsMenuOpened}
        onClose={handleActionsMenuClose}
        anchorEl={actionsMenuButtonRef.current}
        boundariesElement={menuBoundariesElement}
        items={actions
          ?.filter(
            (action) =>
              (selectedItem &&
                action.isDisabled &&
                !action.isDisabled(selectedItem)) ||
              !action.isDisabled
          )
          .map((action) => ({
            label: action.label,
            onClick: handleActionsMenuItemClick(action.handler)
          }))}
      ></Menu>
    </s.Container>
  );
};
