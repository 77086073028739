import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

export const ProjectsContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

export const Description = styled(Typography)`
  margin: ${({ theme }) => theme.spacing(1, 0, 0, 0)};
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: smaller;
`;

export const CurrencyFormControl = styled(FormControl)`
  width: 120px;
`;
