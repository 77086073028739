import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import PhoneForwardedOutlinedIcon from "@mui/icons-material/PhoneForwardedOutlined";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircle from "@mui/icons-material/AccountCircle";
import HelpIcon from "@mui/icons-material/Help";
import NightsStayIcon from "@mui/icons-material/NightsStay";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import Badge from "@mui/material/Badge";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useKeycloak } from "@react-keycloak/web";
import { ContactSupportDialog } from "components/common/ContactSupportDialog";
import { MegaphoneIcon } from "components/common/icons/MegaphoneIcon";
import { Menu } from "components/common/Menu";
import { NewsFeed } from "components/NewsFeed";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as authActions from "modules/auth/actions";
import { userSelector } from "modules/auth/selectors";
import * as newsActions from "modules/news/actions";
import {
  arePostsMarkingAsReadSelector,
  hasMorePostsSelector,
  postsSelector
} from "modules/news/selectors";
import * as pollingActions from "modules/polling/actions";
import {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { isString } from "typeGuards/isString";
import { useDarkMode } from "usehooks-ts";
import { appConfig } from "../../appConfig";
import { DEFAULT_PAGINATION_LIMIT, ELEMENT_IDS } from "../../constants";
import * as s from "./styles";
import { projectSelector } from "modules/projects/selectors";
import { Chip } from "@mui/material";

const POLL_ID_PREFIX = "CONTENT";

const POLL_IDS = {
  newsFeedPosts: "NEWS_FEED_POSTS"
};

export const Content = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const project = useSelector(projectSelector);
  const [isUserMenuOpened, setIsUserMenuOpened] = useState<boolean>(false);
  const [isHelpMenuOpened, setIsHelpMenuOpened] = useState<boolean>(false);
  const user = useSelector(userSelector);
  const [isContactSupportDialogOpened, setIsContactSupportDialogOpened] =
    useState(false);
  const [
    isFooterContactSupportDialogOpened,
    setIsFooterContactSupportDialogOpened
  ] = useState(false);
  const [isNewsFeedSidebarOpened, setIsNewsFeedSidebar] = useState(false);
  const previousIsNewsFeedSidebarOpened = usePrevious(isNewsFeedSidebarOpened);
  const { isDarkMode, toggle } = useDarkMode();
  const newsFeedPosts = useSelector(postsSelector);
  const hasMoreNewsFeedPosts = useSelector(hasMorePostsSelector);
  const areNewsFeedPostsMarkingAsRead = useSelector(
    arePostsMarkingAsReadSelector
  );
  const previousAreNewsFeedPostsMarkingAsRead = usePrevious(
    areNewsFeedPostsMarkingAsRead
  );

  const currentDate = Date.now();

  const areNewsFeedUpdatesExist = useMemo(
    () =>
      Boolean(
        newsFeedPosts.length > 0 &&
          newsFeedPosts[0].created_at &&
          user &&
          ((user.attributes?.["lastNewsFeedPostSeen"] &&
            isString(user.attributes["lastNewsFeedPostSeen"][0]) &&
            new Date(newsFeedPosts[0].created_at) >
              new Date(user.attributes["lastNewsFeedPostSeen"][0])) ||
            !user.attributes?.["lastNewsFeedPostSeen"] ||
            !user.attributes?.["lastNewsFeedPostSeen"][0])
      ),
    [newsFeedPosts, user]
  );
  const isBadgeVisible = useMemo(() => {
    if (!user) return false;
    const lastNews = newsFeedPosts[0]?.created_at;
    const lastSeen = user.attributes?.["lastNewsFeedPostSeen"]?.[0];

    if (!lastNews || !lastSeen || !isString(lastSeen)) return false;

    const lastNewsDate = new Date(lastNews).getTime();
    const lastSeenDate = new Date(lastSeen).getTime();

    return (
      lastSeenDate - lastNewsDate <= 1 * 30 * 1000 &&
      currentDate - lastSeenDate < 1 * 30 * 1000
    ); // badge visible 30 sec if there is some updates
  }, [newsFeedPosts, user, currentDate]);

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.newsFeedPosts}`,
        action: newsActions.getNewsFeedPosts.started({
          limit: DEFAULT_PAGINATION_LIMIT
        })
      })
    );
  });

  useEffect(() => {
    if (
      previousAreNewsFeedPostsMarkingAsRead &&
      !areNewsFeedPostsMarkingAsRead
    ) {
      dispatch(authActions.getUserDetails.started({}));
    }
  }, [
    previousAreNewsFeedPostsMarkingAsRead,
    areNewsFeedPostsMarkingAsRead,
    dispatch
  ]);

  useEffect(() => {
    if (
      !previousIsNewsFeedSidebarOpened &&
      isNewsFeedSidebarOpened &&
      !areNewsFeedUpdatesExist
    ) {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.newsFeedPosts}`
        })
      );
    }

    if (previousIsNewsFeedSidebarOpened && !isNewsFeedSidebarOpened) {
      dispatch(
        pollingActions.startPolling({
          id: `${POLL_ID_PREFIX}/${POLL_IDS.newsFeedPosts}`,
          action: newsActions.getNewsFeedPosts.started({
            limit: DEFAULT_PAGINATION_LIMIT
          })
        })
      );
    }
  }, [
    previousIsNewsFeedSidebarOpened,
    isNewsFeedSidebarOpened,
    dispatch,
    areNewsFeedUpdatesExist
  ]);

  useEffect(() => {
    if (areNewsFeedUpdatesExist) {
      setIsNewsFeedSidebar(true);
    }
  }, [areNewsFeedUpdatesExist]);

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(newsActions.clear());
  });

  const handleChangeTheme = () => {
    toggle();
  };

  const handleUserMenuButtonClick = useCallback(() => {
    setIsUserMenuOpened(!isUserMenuOpened);
  }, [isUserMenuOpened]);

  const handleUserMenuClose = useCallback(() => {
    setIsUserMenuOpened(false);
  }, []);

  const userMenuButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleSettingsMenuItemClick = useCallback(() => {
    void keycloak.accountManagement();
  }, [keycloak]);

  const handleLogoutMenuItemClick = useCallback(() => {
    dispatch(authActions.logout.started());
    handleUserMenuClose();
  }, [handleUserMenuClose, dispatch]);

  const handleHelpMenuButtonClick = useCallback(() => {
    setIsHelpMenuOpened(!isHelpMenuOpened);
  }, [isHelpMenuOpened]);

  const handleNewsButtonClick = useCallback(() => {
    setIsNewsFeedSidebar(true);
  }, []);

  const handleNewsFeedSidebarClose = useCallback(() => {
    setIsNewsFeedSidebar(false);
  }, []);

  const handleHelpMenuClose = useCallback(() => {
    setIsHelpMenuOpened(false);
  }, []);

  const helpMenuButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleDocumentationMenuItemClick = useCallback(() => {
    window.open(appConfig.documentationUrl, "_blank");
    handleHelpMenuClose();
  }, [handleHelpMenuClose]);

  const handleContactSupportMenuItemClick = useCallback(() => {
    setIsContactSupportDialogOpened(true);
    handleHelpMenuClose();
  }, [handleHelpMenuClose]);

  const handleContactSupportDialogClose = useCallback(() => {
    setIsContactSupportDialogOpened(false);
  }, []);

  const handleLoadMoreNewsFeedPosts = () => {
    dispatch(
      newsActions.getNewsFeedPosts.started({
        limit: DEFAULT_PAGINATION_LIMIT,
        lastId: newsFeedPosts
          ? Number(newsFeedPosts[newsFeedPosts.length - 1].id)
          : undefined
      })
    );
  };

  const handleFooterContactSupportDialogClose = useCallback(() => {
    setIsFooterContactSupportDialogOpened(false);
  }, []);

  const helpMenuItems = [
    {
      label: "Documentation",
      onClick: handleDocumentationMenuItemClick,
      isHidden: !appConfig.documentationUrl
    },
    {
      label: "Contact support",
      onClick: handleContactSupportMenuItemClick,
      isHidden: appConfig.supportMod !== "dialog"
    }
  ];

  const phoneNumbers = appConfig.supportPhones
    ? appConfig.supportPhones.split(";").map((phone) => (
        <Typography variant="body1" key={phone} color="secondary" gutterBottom>
          {phone}
        </Typography>
      ))
    : "";

  const activeHelpMenuItems = helpMenuItems.filter((item) => !item.isHidden);

  const footerText = appConfig.theme.footerText || "eww ag | ITandTEL";

  const footerTermsText =
    appConfig.regionGroup === "Switzerland"
      ? "About us"
      : "Terms and conditions";

  const footerElements = [
    {
      id: "copyright",
      component: (
        <>
          © {new Date().getFullYear()} {footerText}
        </>
      ),
      isHidden: !appConfig.isCopyrightEnabled
    },
    {
      id: "privacyUrl",
      component: (
        <Link
          href={appConfig.privacyUrl}
          rel={"noopener noreferrer"}
          target={"_blank"}
        >
          Privacy
        </Link>
      ),
      isHidden: !appConfig.privacyUrl
    },
    {
      id: "termsUrl",
      component: (
        <Link
          href={appConfig.termsUrl}
          rel={"noopener noreferrer"}
          target={"_blank"}
        >
          {footerTermsText}
        </Link>
      ),
      isHidden: !appConfig.termsUrl
    },
    {
      id: "supportEmail",
      component: (
        <>
          {appConfig.supportMod === "modal" && (
            <s.SupportInfoLink
              onClick={() => setIsFooterContactSupportDialogOpened(true)}
            >
              Contact support
            </s.SupportInfoLink>
          )}
          <Modal
            open={isFooterContactSupportDialogOpened}
            onClose={handleFooterContactSupportDialogClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <s.ModalContentBox>
              <s.TitleContainer>
                <s.ModalTypography variant="h5" gutterBottom>
                  How can we help?
                </s.ModalTypography>
                <Tooltip title={"Close"}>
                  <span>
                    <IconButton
                      onClick={handleFooterContactSupportDialogClose}
                      color={"inherit"}
                    >
                      <CloseIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </s.TitleContainer>

              <Typography variant="h6" component="h2" gutterBottom>
                Please, describe your problem the way you prefer...
              </Typography>

              <ListItem>
                <ListItemIcon>
                  <ForwardToInboxOutlinedIcon color="action" />
                </ListItemIcon>
                <ListItemText>
                  <Link
                    color="secondary"
                    href={`mailto:${String(appConfig.supportEmail)}`}
                  >
                    {appConfig.supportEmail}
                  </Link>
                </ListItemText>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <PhoneForwardedOutlinedIcon color="action" />
                </ListItemIcon>
                <ListItemText>{phoneNumbers}</ListItemText>
              </ListItem>
            </s.ModalContentBox>
          </Modal>{" "}
          {appConfig.supportMod === "link" && (
            <Link href={`mailto:${String(appConfig.supportEmail)}`}>
              Contact support
            </Link>
          )}
        </>
      ),
      isHidden: appConfig.supportMod === "dialog"
    }
  ];

  return (
    <s.Container>
      <s.Header>
        <s.BreadcrumbsContainer id={ELEMENT_IDS.BREADCRUMBS_CONTAINER} />
        <s.Toolbar>
          {project && (
            <s.UsernameContainer>
              <Tooltip title={"Region"} arrow>
                <Chip label={project.region} />
              </Tooltip>
            </s.UsernameContainer>
          )}
          {user && (
            <s.UsernameContainer>
              <Tooltip title={"User"} arrow>
                <Typography data-testid={"content-toolbar-username"}>
                  {[user.firstName, user.lastName].filter(Boolean).join(" ") ||
                    String(user.username)}
                </Typography>
              </Tooltip>
            </s.UsernameContainer>
          )}
          <Tooltip title={"User menu"} arrow>
            <span>
              <IconButton
                onClick={handleUserMenuButtonClick}
                color={"inherit"}
                ref={userMenuButtonRef}
                // title={"User menu"}
              >
                <AccountCircle />
              </IconButton>
            </span>
          </Tooltip>
          <Menu
            isOpened={isUserMenuOpened}
            onClose={handleUserMenuClose}
            anchorEl={userMenuButtonRef.current}
            items={[
              {
                label: "Settings",
                onClick: handleSettingsMenuItemClick
              },
              {
                label: "Log out",
                onClick: handleLogoutMenuItemClick
              }
            ]}
          />
          <Tooltip title={"Help"} arrow>
            <span>
              <IconButton
                // title={"Help"}
                onClick={handleHelpMenuButtonClick}
                color={"inherit"}
                ref={helpMenuButtonRef}
              >
                <HelpIcon />
              </IconButton>
            </span>
          </Tooltip>
          {Object.values(appConfig.theme).every((x) => !x) && (
            <Tooltip title={"Toggle light/dark mode"} arrow>
              <span>
                <IconButton
                  onClick={handleChangeTheme}
                  color={"inherit"}
                  // title={"Toggle light/dark mode"}
                >
                  {isDarkMode ? <WbSunnyIcon /> : <NightsStayIcon />}
                </IconButton>
              </span>
            </Tooltip>
          )}
          {appConfig.isNewsFeedEnabled && (
            <Tooltip title={"News"} arrow>
              <span>
                <IconButton
                  onClick={handleNewsButtonClick}
                  color={"inherit"}
                  // title={"News"}
                >
                  <Badge
                    color={"secondary"}
                    variant={"dot"}
                    invisible={!isBadgeVisible}
                    // invisible={!areNewsFeedUpdatesExist}
                  >
                    <MegaphoneIcon />
                  </Badge>
                </IconButton>
              </span>
            </Tooltip>
          )}
          {activeHelpMenuItems.length > 0 && (
            <Menu
              isOpened={isHelpMenuOpened}
              onClose={handleHelpMenuClose}
              anchorEl={helpMenuButtonRef.current}
              items={activeHelpMenuItems}
            />
          )}
        </s.Toolbar>
      </s.Header>
      <s.ContentContainer id={ELEMENT_IDS.CONTENT_CONTAINER}>
        {children}
      </s.ContentContainer>
      <s.Footer>
        <Typography variant={"caption"}>
          {footerElements
            .filter((element) => !element.isHidden)
            .map((element, i, arr) =>
              arr.length > 0 && i !== arr.length - 1 ? (
                <Fragment key={element.id}>{element.component} | </Fragment>
              ) : (
                <Fragment key={element.id}>{element.component}</Fragment>
              )
            )}
        </Typography>
      </s.Footer>
      <ContactSupportDialog
        isOpened={isContactSupportDialogOpened}
        onClose={handleContactSupportDialogClose}
      />
      {appConfig.isNewsFeedEnabled && (
        <Drawer
          data-testid={"news-feed"}
          anchor={"right"}
          open={isNewsFeedSidebarOpened}
          onClose={handleNewsFeedSidebarClose}
        >
          <NewsFeed
            posts={newsFeedPosts}
            onClose={handleNewsFeedSidebarClose}
            hasMore={hasMoreNewsFeedPosts}
            onLoadMore={handleLoadMoreNewsFeedPosts}
          />
        </Drawer>
      )}
    </s.Container>
  );
};
