import { MobileDatePicker } from "@mui/x-date-pickers";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { Head } from "components/common/Head";
import { Table } from "components/common/Table";
import {
  TableColumn,
  TABLE_SORTING_TYPES
} from "components/common/Table/types";
import startOfMonth from "date-fns/startOfMonth";
import sub from "date-fns/sub";
import { useMount } from "hooks/useMount";
import { useUnmount } from "hooks/useUnmount";
import * as billingActions from "modules/billing/actions";
import {
  isOrganizationBillingDataLoadingSelector,
  organizationBillingDataSelector,
  tableOrganizationProjectsBillingDataSelector,
  tableOrganizationTotalBillingDataSelector
} from "modules/billing/selectors";
import { TableOrganizationProjectBillingData } from "modules/billing/types";
import * as enterprisesActions from "modules/enterprises/actions";
import { organizationSelector } from "modules/enterprises/selectors";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useParams } from "react-router-dom";
import { formatDate } from "utils/formatDate";
import {
  DATE_FORMATS,
  DEFAULT_CURRENCY,
  PROJECT_STATUS_LABELS,
  ROUTES
} from "../../constants";
import * as s from "./styles";
import { DATE_TYPES, PROJECT_STATUSES } from "./types";
import { addDays, subDays } from "date-fns";

const tableColumns: TableColumn<TableOrganizationProjectBillingData>[] = [
  { key: "name", label: "Project Name" },
  { key: "region", label: "Region" },
  { key: "status", label: "Status" }
];

const CURRENCY_SELECT_ID = "currency";

const title = "Billing";

export const Billing: FC = () => {
  const dispatch = useDispatch();
  const matchParams = useParams<{
    organizationId: string;
  }>();
  const organization = useSelector(organizationSelector);
  const billingData = useSelector(organizationBillingDataSelector);
  const isBillingDataLoading = useSelector(
    isOrganizationBillingDataLoadingSelector
  );
  const tableBillingData = useSelector(
    tableOrganizationProjectsBillingDataSelector
  );
  const tableBillingDataTotal = useSelector(
    tableOrganizationTotalBillingDataSelector
  );
  const currentDate = new Date();
  const minDate = sub(currentDate, { days: 120 });
  const [startDate, setStartDate] = useState<string | null>(
    formatDate(startOfMonth(currentDate), DATE_FORMATS.ISO_DATE)
  );
  const [endDate, setEndDate] = useState<string | null>(
    formatDate(currentDate, DATE_FORMATS.ISO_DATE)
  );
  const [currency, setCurrency] = useState<string>(DEFAULT_CURRENCY);

  const currencies = useMemo(
    () => (billingData ? Object.keys(billingData.costs) : []),
    [billingData]
  );

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Billing",
      url: generatePath(ROUTES.BILLING, {
        organizationId: matchParams.organizationId
      })
    }
  ];

  const generateTableItemURL = useCallback(
    (id: string) =>
      generatePath(ROUTES.PROJECT, {
        organizationId: matchParams.organizationId,
        regionId: billingData?.projects
          ?.find((project) => project.id === id)
          ?.region.toLowerCase(),
        projectId: id
      }),
    [billingData, matchParams.organizationId]
  );

  useMount(() => {
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
  });

  useUnmount(() => {
    dispatch(enterprisesActions.clear());
    dispatch(billingActions.clear());
  });

  useEffect(() => {
    if (startDate && endDate) {
      dispatch(
        billingActions.getOrganizationBillingData.started({
          startDate,
          endDate,
          id: matchParams.organizationId!
        })
      );
    }
  }, [dispatch, startDate, endDate, matchParams.organizationId]);

  const handleDateChange = useCallback(
    (dateType: DATE_TYPES) => (date: Date | null) => {
      const dateValue = date ? formatDate(date, DATE_FORMATS.ISO_DATE) : date;
      switch (dateType) {
        case DATE_TYPES.START:
          setStartDate(dateValue);
          break;
        case DATE_TYPES.END:
          setEndDate(dateValue);
      }
    },
    []
  );

  const handleCurrencyChange = useCallback((event: SelectChangeEvent) => {
    setCurrency(event.target.value);
  }, []);

  return (
    <>
      <Head title={title} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Typography variant={"h4"} component={"h2"}>
        {title}
      </Typography>
      <s.ProjectsContainer>
        <s.Description>
          This billing data is only for the initial reference. The final costs
          will be provided in the invoice.
        </s.Description>
        <s.Description>
          Billing data is available for the last 120 days
        </s.Description>
        <Table
          isSearchEnabled={true}
          isSortingEnabled={true}
          rows={tableBillingData || []}
          columns={[
            ...tableColumns,
            {
              key: `cost-${currency}`,
              label: "Cost",
              sortingType: TABLE_SORTING_TYPES.NUMBER
            }
          ]}
          totalRow={tableBillingDataTotal || undefined}
          itemLink={{
            column: "name",
            getURL: generateTableItemURL,
            isEnabled: (billingData) =>
              billingData.status !==
              PROJECT_STATUS_LABELS[PROJECT_STATUSES.DELETED]
          }}
          isLoading={isBillingDataLoading}
          toolbarItems={
            <>
              <MobileDatePicker
                label={"Start date"}
                onChange={handleDateChange(DATE_TYPES.START)}
                value={startDate ? new Date(startDate) : null}
                format={DATE_FORMATS.DATE}
                minDate={minDate}
                // maxDate={endDate ? subDays(new Date(endDate), 1) : undefined}
                maxDate={endDate ? new Date(endDate) : undefined}
                disableFuture={true}
                sx={{ marginRight: "10px" }}
                slotProps={{
                  textField: {
                    InputProps: { size: "small" }
                  }
                }}
              />
              <MobileDatePicker
                label={"End date"}
                onChange={handleDateChange(DATE_TYPES.END)}
                value={endDate ? new Date(endDate) : null}
                format={DATE_FORMATS.DATE}
                // minDate={startDate ? addDays(new Date(startDate), 1) : minDate}
                minDate={startDate ? new Date(startDate) : minDate}
                disableFuture={true}
                sx={{ marginRight: "10px" }}
                slotProps={{
                  textField: {
                    InputProps: { size: "small" }
                  }
                }}
              />
              {currencies.length > 0 && (
                <s.CurrencyFormControl>
                  <InputLabel id={CURRENCY_SELECT_ID}>Currency</InputLabel>
                  <Select
                    label={"Currency"}
                    labelId={CURRENCY_SELECT_ID}
                    value={currency}
                    onChange={handleCurrencyChange}
                    size={"small"}
                  >
                    {currencies.map((currency) => (
                      <MenuItem key={currency} value={currency}>
                        {currency.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </s.CurrencyFormControl>
              )}
            </>
          }
        />
      </s.ProjectsContainer>
    </>
  );
};
