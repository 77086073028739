import { AxiosResponse } from "axios";
import { addMinutes } from "date-fns";
import sub from "date-fns/sub";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { isPollStoppedSaga } from "modules/polling/sagas";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { formatDate } from "utils/formatDate";
import { getAxiosErrorMessage } from "utils/getAxiosErrorMessage";
import { axiosInstance } from "../../axios";
import { DATE_FORMATS } from "../../constants";
import * as actions from "./actions";
import {
  AddFirewallParams,
  AddFirewallResponse,
  AttachVolumeParams,
  AttachVolumeResponse,
  ConfirmResizeInstanceParams,
  ConfirmResizeInstanceResponse,
  CreateInstanceParams,
  CreateInstanceResponse,
  CreateInterfaceParams,
  CreateInterfaceResponse,
  DeleteInstanceParams,
  DeleteInstanceResponse,
  DeleteInterfaceParams,
  DeleteInterfaceResponse,
  DetachVolumeParams,
  DetachVolumeResponse,
  GetFlavorParams,
  GetFlavorResponse,
  GetFlavorsParams,
  GetFlavorsResponse,
  GetImageParams,
  GetImageResponse,
  GetImagesParams,
  GetImagesResponse,
  GetInstanceActionLogsParams,
  GetInstanceActionLogsResponse,
  GetInstanceFirewallsParams,
  GetInstanceFirewallsResponse,
  GetInstanceLimitsParams,
  GetInstanceLimitsResponse,
  GetInstanceLogParams,
  GetInstanceLogResponse,
  GetInstanceMetricParams,
  GetInstanceMetricResponse,
  GetInstanceParams,
  GetInstanceResponse,
  GetInstancesParams,
  GetInstancesResponse,
  GetInterfaceParams,
  GetInterfaceResponse,
  GetInterfacesParams,
  GetInterfacesResponse,
  GetRemoteConsoleURLParams,
  GetRemoteConsoleURLResponse,
  RemoveFirewallParams,
  RemoveFirewallResponse,
  ResizeInstanceParams,
  ResizeInstanceResponse,
  RestartInstanceParams,
  RevertResizeInstanceParams,
  RevertResizeInstanceResponse,
  StartInstanceParams,
  StopInstanceParams,
  UpdateInstanceParams,
  UpdateInstanceResponse
} from "./types";

const gothamWayneApi = `gotham-wayne-tower/method/`;

export function* getInstanceSaga(
  action: Action<GetInstanceParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, id } = action.payload;
    const response: AxiosResponse<GetInstanceResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-compute/method/${projectId}/instances/${id}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getInstance.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getInstance.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get virtual machine data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getInstancesSaga(
  action: Action<GetInstancesParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId } = action.payload;
    const response: AxiosResponse<GetInstancesResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-compute/method/${projectId}/instances`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getInstances.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getInstances.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get virtual machines data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createInstanceSaga(
  action: Action<CreateInstanceParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the virtual machine...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, data } = action.payload;
    const response: AxiosResponse<CreateInstanceResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-compute/method/${projectId}/instances`,
      data
    );
    yield put(
      actions.createInstance.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Virtual machine has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createInstance.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create virtual machine",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* updateInstanceSaga(
  action: Action<UpdateInstanceParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Updating the virtual machine...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id, data } = action.payload;
    const response: AxiosResponse<UpdateInstanceResponse> = yield call(
      axiosInstance.put,
      `gotham-${regionId}-compute/method/${projectId}/instances/${id}`,
      data
    );
    yield put(
      actions.updateInstance.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Virtual machine has been successfully updated.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.updateInstance.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update virtual machine",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* resizeInstanceSaga(
  action: Action<ResizeInstanceParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to resize virtual machine...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id, flavorId } = action.payload;
    const response: AxiosResponse<ResizeInstanceResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-compute/method/${projectId}/instances/${id}/resize/${flavorId}`
    );
    yield put(
      actions.resizeInstance.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Request to resize virtual machine has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.resizeInstance.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request to resize virtual machine",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* confirmResizeInstanceSaga(
  action: Action<ConfirmResizeInstanceParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Confirming the virtual machine resize...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id } = action.payload;
    const response: AxiosResponse<ConfirmResizeInstanceResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-compute/method/${projectId}/instances/${id}/resize/confirm`
    );
    yield put(
      actions.confirmResizeInstance.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Virtual machine resize has been successfully confirmed.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.confirmResizeInstance.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to confirm virtual machine resize",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* revertResizeInstanceSaga(
  action: Action<RevertResizeInstanceParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Reverting the virtual machine resize...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id } = action.payload;
    const response: AxiosResponse<RevertResizeInstanceResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-compute/method/${projectId}/instances/${id}/resize/revert`
    );
    yield put(
      actions.revertResizeInstance.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Virtual machine resize has been successfully reverted.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.revertResizeInstance.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to revert virtual machine resize",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteInstanceSaga(
  action: Action<DeleteInstanceParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to delete the virtual machine...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id } = action.payload;
    const response: AxiosResponse<DeleteInstanceResponse> = yield call(
      axiosInstance.delete,
      `gotham-${regionId}-compute/method/${projectId}/instances/${id}`
    );
    yield put(
      actions.deleteInstance.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Request to delete virtual machine has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteInstance.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request to delete virtual machine",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* startInstanceSaga(
  action: Action<StartInstanceParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Starting the virtual machine...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id } = action.payload;
    yield call(
      axiosInstance.post,
      `gotham-${regionId}-compute/method/${projectId}/instances/${id}/start`
    );
    yield put(
      actions.startInstance.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Virtual machine has been successfully started.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.startInstance.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to start virtual machine",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* restartInstanceSaga(
  action: Action<RestartInstanceParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Rebooting the virtual machine...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id } = action.payload;
    yield call(
      axiosInstance.post,
      `gotham-${regionId}-compute/method/${projectId}/instances/${id}/restart`,
      null,
      {
        params: {
          type: action.payload.type
        }
      }
    );
    yield put(
      actions.restartInstance.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Virtual machine has been successfully rebooted.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.restartInstance.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to reboot virtual machine",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* stopInstanceSaga(
  action: Action<StopInstanceParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Stopping the virtual machine...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id } = action.payload;
    yield call(
      axiosInstance.post,
      `gotham-${regionId}-compute/method/${projectId}/instances/${id}/stop`
    );
    yield put(
      actions.stopInstance.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Virtual machine has been successfully stopped.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.stopInstance.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to stop virtual machine",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getFlavorSaga(
  action: Action<GetFlavorParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, id } = action.payload;
    const response: AxiosResponse<GetFlavorResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-compute/method/${projectId}/flavors/${id}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getFlavor.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getFlavor.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get flavor data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getFlavorsSaga(
  action: Action<GetFlavorsParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, type } = action.payload;
    const response: AxiosResponse<GetFlavorsResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-compute/method/${projectId}/flavors`,
      {
        params: {
          type
        }
      }
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getFlavors.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getFlavors.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get flavors data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getImageSaga(
  action: Action<GetImageParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, id } = action.payload;
    const response: AxiosResponse<GetImageResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-compute/method/${projectId}/images/${id}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getImage.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getImage.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get image data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getImagesSaga(
  action: Action<GetImagesParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId } = action.payload;
    const response: AxiosResponse<GetImagesResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-compute/method/${projectId}/images`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getImages.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getImages.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get images data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getInterfaceSaga(
  action: Action<GetInterfaceParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, instanceId, id } = action.payload;
    const response: AxiosResponse<GetInterfaceResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-compute/method/${projectId}/instances/${instanceId}/interfaces/${id}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getInterface.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getInterface.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get interface data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getInterfacesSaga(
  action: Action<GetInterfacesParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, instanceId } = action.payload;
    const response: AxiosResponse<GetInterfacesResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-network/method/${projectId}/devices/${instanceId}/interfaces`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getInterfaces.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getInterfaces.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get interfaces data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createInterfaceSaga(
  action: Action<CreateInterfaceParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the interface...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, instanceId, data } = action.payload;
    const response: AxiosResponse<CreateInterfaceResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-compute/method/${projectId}/instances/${instanceId}/interfaces`,
      data
    );
    yield put(
      actions.createInterface.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Interface has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createInterface.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create interface",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteInterfaceSaga(
  action: Action<DeleteInterfaceParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to delete interface...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, instanceId, id } = action.payload;
    const response: AxiosResponse<DeleteInterfaceResponse> = yield call(
      axiosInstance.delete,
      `gotham-${regionId}-compute/method/${projectId}/instances/${instanceId}/interfaces/${id}`
    );
    yield put(
      actions.deleteInterface.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Request to delete interface has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteInterface.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request to delete interface",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getInstanceLimitsSaga(
  action: Action<GetInstanceLimitsParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId } = action.payload;
    const response: AxiosResponse<GetInstanceLimitsResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-compute/method/${projectId}/compute/limits`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getInstanceLimits.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getInstanceLimits.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get instance limits data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* attachVolumeSaga(
  action: Action<AttachVolumeParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Attaching the volume...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, instanceId, volumeId } = action.payload;
    const response: AxiosResponse<AttachVolumeResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-compute/method/${projectId}/instances/${instanceId}/attach-volume/${volumeId}`
    );
    yield put(
      actions.attachVolume.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Volume has been successfully attached.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.attachVolume.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to attach volume",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* detachVolumeSaga(
  action: Action<DetachVolumeParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to detach volume...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, instanceId, volumeId } = action.payload;
    const response: AxiosResponse<DetachVolumeResponse> = yield call(
      axiosInstance.delete,
      `gotham-${regionId}-compute/method/${projectId}/instances/${instanceId}/detach-volume/${volumeId}`
    );
    yield put(
      actions.detachVolume.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Request to detach volume has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.detachVolume.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request sto detach volume",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* addFirewallSaga(
  action: Action<AddFirewallParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Adding the firewall...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, instanceId, firewallId } = action.payload;
    const response: AxiosResponse<AddFirewallResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-compute/method/${projectId}/instances/${instanceId}/firewalls/${firewallId}`
    );
    yield put(
      actions.addFirewall.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Firewall has been successfully added.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(actions.addFirewall.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to add firewall",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* removeFirewallSaga(
  action: Action<RemoveFirewallParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Removing the firewall...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, instanceId, firewallId } = action.payload;
    const response: AxiosResponse<RemoveFirewallResponse> = yield call(
      axiosInstance.delete,
      `gotham-${regionId}-compute/method/${projectId}/instances/${instanceId}/firewalls/${firewallId}`
    );
    yield put(
      actions.removeFirewall.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Firewall has been successfully removed.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.removeFirewall.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to remove firewall",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getInstanceFirewallsSaga(
  action: Action<GetInstanceFirewallsParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, instanceId } = action.payload;
    const response: AxiosResponse<GetInstanceFirewallsResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-compute/method/${projectId}/instances/${instanceId}/firewalls`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getInstanceFirewalls.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getInstanceFirewalls.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get virtual machine firewalls data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getRemoteConsoleURLSaga(
  action: Action<GetRemoteConsoleURLParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, instanceId } = action.payload;
    const response: AxiosResponse<GetRemoteConsoleURLResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-compute/method/${projectId}/instances/${instanceId}/remote-console`
    );
    yield put(
      actions.getRemoteConsoleURL.done({
        params: action.payload,
        result: response.data
      })
    );
    window.open(response.data.url, "_blank");
  } catch (e) {
    yield put(
      actions.getRemoteConsoleURL.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get remote console URL",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getInstanceLogSaga(
  action: Action<GetInstanceLogParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, instanceId, logLength } = action.payload;
    const response: AxiosResponse<GetInstanceLogResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-compute/method/${projectId}/instances/${instanceId}/logs`,
      { params: { length: logLength } }
    );
    yield put(
      actions.getInstanceLog.done({
        params: action.payload,
        result: response.data
      })
    );
  } catch (e) {
    yield put(
      actions.getInstanceLog.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get virtual machine log",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getInstanceActionLogsSaga(
  action: Action<GetInstanceActionLogsParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, instanceId } = action.payload;
    const response: AxiosResponse<GetInstanceActionLogsResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-compute/method/${projectId}/instances/${instanceId}/action-logs`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getInstanceActionLogs.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getInstanceActionLogs.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get virtual machine action logs",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getInstanceMetricsSaga(
  action: Action<GetInstanceMetricParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, instanceId } = action.payload;

    const startDate = sub(new Date(), { hours: 1 });

    const response: AxiosResponse<GetInstanceMetricResponse> = yield call(
      axiosInstance.get,
      `${gothamWayneApi}metrics/regions/${regionId}/projects/${projectId}/instances/${instanceId}`,
      {
        params: {
          startDate: formatDate(
            addMinutes(startDate, startDate.getTimezoneOffset()),
            DATE_FORMATS.ISO_DATETIME
          )
        }
      }
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getInstanceMetrics.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getInstanceMetrics.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get virtual machine metrics",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeLeading(actions.getInstance.started, getInstanceSaga),
    takeLeading(actions.getInstances.started, getInstancesSaga),
    takeEvery(actions.createInstance.started, createInstanceSaga),
    takeEvery(actions.updateInstance.started, updateInstanceSaga),
    takeEvery(actions.resizeInstance.started, resizeInstanceSaga),
    takeEvery(actions.confirmResizeInstance.started, confirmResizeInstanceSaga),
    takeEvery(actions.revertResizeInstance.started, revertResizeInstanceSaga),
    takeEvery(actions.deleteInstance.started, deleteInstanceSaga),
    takeLeading(actions.getFlavor.started, getFlavorSaga),
    takeLeading(actions.getFlavors.started, getFlavorsSaga),
    takeLeading(actions.getImage.started, getImageSaga),
    takeLeading(actions.getImages.started, getImagesSaga),
    takeEvery(actions.startInstance.started, startInstanceSaga),
    takeEvery(actions.restartInstance.started, restartInstanceSaga),
    takeEvery(actions.stopInstance.started, stopInstanceSaga),
    takeLeading(actions.getInterface.started, getInterfaceSaga),
    takeLeading(actions.getInterfaces.started, getInterfacesSaga),
    takeEvery(actions.createInterface.started, createInterfaceSaga),
    takeEvery(actions.deleteInterface.started, deleteInterfaceSaga),
    takeLeading(actions.getInstanceLimits.started, getInstanceLimitsSaga),
    takeEvery(actions.attachVolume.started, attachVolumeSaga),
    takeEvery(actions.detachVolume.started, detachVolumeSaga),
    takeEvery(actions.addFirewall.started, addFirewallSaga),
    takeEvery(actions.removeFirewall.started, removeFirewallSaga),
    takeLeading(actions.getInstanceFirewalls.started, getInstanceFirewallsSaga),
    takeLeading(actions.getRemoteConsoleURL.started, getRemoteConsoleURLSaga),
    takeLeading(actions.getInstanceLog.started, getInstanceLogSaga),
    takeLeading(
      actions.getInstanceActionLogs.started,
      getInstanceActionLogsSaga
    ),
    takeLeading(actions.getInstanceMetrics.started, getInstanceMetricsSaga)
  ]);
}
